import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';
import { breakPoints, generalConstants } from 'src/constants/general';
import { convertedHours, roundedNumber } from 'src/utils/function';
import { employeeDetailById } from 'src/services/employee';
import { GoStar } from 'react-icons/go';
import cupIcon from 'src/assets/svgs/cup.svg';
import ModalContentBlock from 'src/components/Common/ModalContentBlock';
import EmployeeDetail from 'src/components/Employee/EmployeeDetail';
import ResponsiveWorkerListAccordion from 'src/components/Responsive/home/ResponsiveWorkerListAccordion';

const Workers = ({
    peopleOverViewData,
    userData,
    isMobile,
    workerOptionSelectFunction,
    workerOptions,
    workerOptionValue,
    modalOpen,
    setModalOpen,
    moreDetailStepResponsive,
    setMoreDetailStepResponsive,
    employDetailData,
    setEmployDetailData,
    listData,
    setListData,
    allListData,
    openAcoordionResponsive,
    setOpenAcoordionResponsive,
    setResponsiveView,
    reviewOptionValue,
    setReviewOptionValue,
    onSaveChangesSuccess = () => {},
}: any) => {
    useEffect(() => {
        getJobCompanyList();
    }, [allListData?.companyData, allListData?.jobTypeData]);

    const getJobCompanyList = async () => {
        setListData((prevData: any) => ({
            ...prevData,
            companyList: allListData?.companyData,
        }));
        setListData((prevData: any) => ({
            ...prevData,
            jobList: allListData?.jobTypeData,
        }));
    };

    const onRowClick = async (event: any) => {
        await getEmployDetailData(event.data.id);
        setModalOpen(true);
        setMoreDetailStepResponsive(1);
        setResponsiveView('peoples');
    };
    const getEmployDetailData = async (id: any) => {
        const employDetail = await employeeDetailById(`/${id}`);
        setEmployDetailData(employDetail?.data?.data);
    };

    const GetName = (row: any) => (
        <div className="gap-1">
            <div className="flex items-center gap-2 mb-1">
                {row?.reward === 1 && <img src={cupIcon} alt={'cupIcon'} />}
                <p className="text-zinc-800 text-sm font-medium font-['Satoshi Variable'] leading-none">{`${row.name} ${row.surname}`}</p>
            </div>
            <p className="text-[#808080] text-xs font-medium font-['Satoshi Variable']">{row.email}</p>
        </div>
    );
    const GetJobs = (row: any) => <div className="">{`${convertedHours(row.total_jobs)} job`}</div>;
    const GetHours = (row: any) => <div className="">{`${convertedHours(row.total_hours)} hrs.`}</div>;
    const GetRatingHeader = () => (
        <div className="flex items-center gap-1">
            <GoStar />
            <div className="">{'Rating'}</div>
        </div>
    );
    const GetRating = (row: any) => (
        <div className="flex items-center gap-1">
            <GoStar />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{roundedNumber(row.rating)}</div>
        </div>
    );
    return (
        <>
            {isMobile >= breakPoints.mobile && (
                <div className="py-4">
                    <div className="flex justify-between px-6 max-md:px-2 items-center">
                        <div className="text-black80 text-xxl-base text-sm font-medium leading-4">Workers</div>
                        <div className={`dashboard_dropdown ${isMobile < breakPoints.mobile ? 'dashboard_dropdown_responsive' : ''}`}>
                            <SelectBox value={workerOptionValue} onChangeFunc={workerOptionSelectFunction} options={workerOptions} classComp="custom_react_select" menuPlacement="bottom" />
                        </div>
                    </div>
                    <div className={`mt-4 job_history ${userData?.userType === generalConstants.superAdmin ? 'h-[268px] max-lg:h-[300px]' : 'h-[268px]'}  overflow-y-auto px-6 max-md:px-2`}>
                        {peopleOverViewData?.goodPerformers?.length !== 0 ? (
                            <DataTable
                                value={workerOptionValue.value === 'top_workers' ? peopleOverViewData?.goodPerformers?.slice(0, 5) : peopleOverViewData?.goodPerformers?.slice(-5)?.reverse()}
                                tableClassName="logdatatable workerdatatable"
                                onRowClick={onRowClick}
                                emptyMessage="No Records Found"
                                className=""
                            >
                                <Column field="name" header="Name" body={GetName}></Column>
                                <Column field="jobType" header="Job type"></Column>
                                <Column field="total_jobs" header="Jobs #" body={GetJobs}></Column>
                                <Column field="hours" header="Hours #" body={GetHours}></Column>
                                <Column field="rating" header={GetRatingHeader} body={GetRating}></Column>
                            </DataTable>
                        ) : (
                            <div className="emptyMessageShow">No Records Found</div>
                        )}
                    </div>
                </div>
            )}
            {isMobile < breakPoints.mobile && (
                <div className="py-2">
                    <div className="flex justify-between px-6 max-md:px-2 items-center rounded-2xl bg-white py-2">
                        <div className="text-black80 text-xxl-base text-sm font-medium leading-4">Workers</div>
                        <div className={`dashboard_dropdown ${isMobile < breakPoints.mobile ? 'dashboard_dropdown_responsive' : ''}`}>
                            <SelectBox value={workerOptionValue} onChangeFunc={workerOptionSelectFunction} options={workerOptions} classComp="custom_react_select" menuPlacement="bottom" />
                        </div>
                    </div>
                    {peopleOverViewData?.goodPerformers?.length === 0 && (
                        <div
                            className="mt-3 bg-white max-md:h-10 flex justify-center items-center max-lg:font-semibold
                         max-lg:text-xl max-lg:h-12  max-md:font-medium max-md:text-lg rounded-xl"
                        >
                            Data Not Found
                        </div>
                    )}
                    <div className="mt-3">
                        <ResponsiveWorkerListAccordion
                            employListData={workerOptionValue.value === 'top_workers' ? peopleOverViewData?.goodPerformers?.slice(0, 5) : peopleOverViewData?.goodPerformers?.slice(-5)?.reverse()}
                            openAcoordionResponsive={openAcoordionResponsive}
                            setOpenAcoordionResponsive={setOpenAcoordionResponsive}
                            onRowClick={onRowClick}
                        />
                    </div>
                </div>
            )}

            {modalOpen && isMobile >= breakPoints.tablet && (
                <div className={`${modalOpen ? 'max-lg:hidden block' : 'hidden'}`}>
                    <ModalContentBlock
                        onclose={() => {
                            setModalOpen(false);
                            setMoreDetailStepResponsive(0);
                        }}
                        isMoreClass={'w-[60%]'}
                        isModalOpen={modalOpen}
                    >
                        <EmployeeDetail
                            employDetailData={employDetailData}
                            onSaveChangesSuccess={() => {
                                // getEmployList(`?page=${page}&limit=${rows}`);
                                onSaveChangesSuccess();
                                setModalOpen(false);
                                setMoreDetailStepResponsive(0);
                            }}
                            listData={listData}
                            uniqueId={'desktop'}
                            moreDetailStepResponsive={moreDetailStepResponsive}
                            setMoreDetailStepResponsive={setMoreDetailStepResponsive}
                            reviewOptionValue={reviewOptionValue}
                            setReviewOptionValue={setReviewOptionValue}
                        />
                    </ModalContentBlock>
                </div>
            )}
        </>
    );
};
export default Workers;
