import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import westImg from 'src/assets/img/westImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import logImg from 'src/assets/img/logImg.png';
import cupIcon from 'src/assets/svgs/cup.svg';
import flagImg from 'src/assets/img/flagImg.png';
import { convertedHours, roundedNumber } from 'src/utils/function';
import { GoStar } from 'react-icons/go';

const ActiveAvailabelDatatable = ({ employListData, modalOpenAvailabelActive }: any) => {
    const GetIconWithText1 = (row: any) => (
        <div className="flex items-center gap-1">
            <img className="w-6 h-6 rounded-full" src={row.companyName === 'West' ? westImg : row.companyName === 'Log' ? logImg : fsImg} alt="img" />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{row.companyName}</div>
        </div>
    );
    const GetName = (row: any) => (
        <div className="gap-1">
            <div className="flex items-center gap-2 mb-1">
                {row?.reward === 1 && <img src={cupIcon} alt={'cupIcon'} />}
                <p className="text-zinc-800 text-sm font-medium font-['Satoshi Variable'] leading-none">{`${row.name} ${row.surname}`}</p>
            </div>
            <p className="text-[#808080] text-xs font-medium font-['Satoshi Variable']">{row.email}</p>
        </div>
    );
    const GetIconWithText = (row: any) => (
        <div className="flex items-center gap-1">
            <img className="w-6 h-6 rounded-full" src={flagImg} alt="img" />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{row.languageFluency}</div>
        </div>
    );
    const GetHours = (row: any) => <div className="">{convertedHours(row.hours)}</div>;
    const GetRatingHeader = () => (
        <div className="flex items-center gap-1">
            <GoStar />
            <div className="">{'Rating'}</div>
        </div>
    );
    const GetRating = (row: any) => (
        <div className="flex items-center gap-1">
            <GoStar />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{roundedNumber(row.rating)}</div>
        </div>
    );

    return (
        <div className="pt-4">
            <div className="text-center text-zinc-800 text-lg font-medium font-['Satoshi Variable']">{modalOpenAvailabelActive}</div>
            <div className="h-[calc(100vh-150px)] scrollbar-hide overflow-y-auto">
                <DataTable value={employListData} tableClassName="logdatatable" emptyMessage="No Employee Record Found" className="">
                    <Column field="company" header="Company" body={GetIconWithText1}></Column>
                    <Column field="name" header="Name" body={GetName}></Column>
                    <Column field="languagefluency" header="Language fluency" body={GetIconWithText}></Column>
                    <Column field="totalJobs" header="Jobs #"></Column>
                    <Column field="hours" header="Hours #" body={GetHours}></Column>
                    <Column field="rating" header={GetRatingHeader} body={GetRating}></Column>
                </DataTable>
            </div>
        </div>
    );
};
export default ActiveAvailabelDatatable;
