/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import cupIcon from 'src/assets/svgs/cup.svg';
import westImg from 'src/assets/img/westImg.png';
import { BsDot, BsFillStarFill } from 'react-icons/bs';
import logImg from 'src/assets/img/logImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import { convertedHours, convertedHoursMinutes, dateTimeFormate, getRemovetzTime, roundedNumber } from 'src/utils/function';
import { breakPoints, companyName, general, validation } from 'src/constants/general';
import InputMask from 'react-input-mask';
import { deleteEmployee, updateEmployee } from 'src/services/employee';
import { toaster } from 'src/utils/toaster';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInputs from 'src/components/Common/PhoneInputs';
import InputBox from 'src/components/Common/InputBox/InputBox';
import SelectBox from 'src/components/Common/SelectBox/SelectBox';
import moment from 'moment';
import IconWithButton from 'src/components/Common/IconWithButton';
import ModalContentBlock from 'src/components/Common/ModalContentBlock';
import ConfirmationPopUp from 'src/components/Common/ConfirmationPopUp';
import ResponsiveTextWithIconButton from 'src/components/Responsive/ResponsiveTextWithIconButton';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import StarRatings from 'react-star-ratings';
interface PhoneNumber {
    countryCode: string;
    num: string;
    countryName: any;
}

const EmployeeDetail = ({ employDetailData, onSaveChangesSuccess, listData, uniqueId, moreDetailStepResponsive, setMoreDetailStepResponsive, reviewOptionValue, setReviewOptionValue }: any) => {
    const { isMobile }: any = useDeviceDetect();
    const [selectedValue, setSelectedValue] = useState({
        value: employDetailData.status,
        label: employDetailData.status,
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [phoneInputNumber, setPhoneInputNumber] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [number, setNumber] = useState<PhoneNumber>({
        countryCode: employDetailData.countryCode,
        num: employDetailData.phoneNumber,
        countryName: employDetailData.countryName,
    });
    const [value, setValue] = useState({
        startDate: '',
        endDate: '',
    });
    const [error, setError] = useState('');
    const [errorBackend, setErrorBackend] = useState('');
    const [reward, setReward] = useState(employDetailData?.reward ? 'yes' : 'no');
    const [company, setCompany] = useState({
        name: employDetailData?.companyName,
        id: employDetailData?.companyId,
    });
    const reviewOptions = [
        { value: 'time_sheet', label: 'Time Sheet' },
        { value: 'review', label: 'Review' },
    ];
    useEffect(() => {
        if (employDetailData) {
            setFormikField();
            if (employDetailData?.statusRecord) {
                setValue((prev) => ({
                    ...prev,
                    startDate: getRemovetzTime(employDetailData?.statusRecord?.startDate, 'DD|MM|YY'),
                    endDate: getRemovetzTime(employDetailData?.statusRecord?.endDate, 'DD|MM|YY'),
                }));
            }
        }
    }, [employDetailData]);

    const reviewOptionSelectFunction = (data: any) => {
        setReviewOptionValue(data);
    };

    const setFormikField = () => {
        const fullPhoneNumber = employDetailData?.countryCode?.replace('+', '') + employDetailData.phoneNumber;
        formik.setFieldValue('name', employDetailData.name);
        formik.setFieldValue('surName', employDetailData.surname);
        formik.setFieldValue('email', employDetailData.email);
        formik.setFieldValue('backupEmail', employDetailData.backupEmail ? employDetailData.backupEmail : '');
        if (fullPhoneNumber) {
            setPhoneInputNumber(fullPhoneNumber);
            formik.setFieldValue('phoneNumber', `+${fullPhoneNumber}`);
        }
    };

    const onStartChange = (e: any) => {
        const inputValue = e.target.value;
        setValue((prev) => ({
            ...prev,
            startDate: inputValue,
        }));
        checkValidation(inputValue, 'start');
        setErrorBackend('');
    };

    const onEndChange = (e: any) => {
        const inputValue = e.target.value;
        setValue((prev) => ({
            ...prev,
            endDate: e.target.value,
        }));
        checkValidation(inputValue, 'end');
        setErrorBackend('');
    };
    const dateCheckValidation = (endDateValue: any, startDateValue: any) => {
        const checkEndDate = makeDateFormate(endDateValue);
        const checkStartDate = makeDateFormate(startDateValue);
        const today = moment().format('YYYY-MM-DD');

        // Date to check
        const endDateToCheck = moment(checkEndDate, 'YYYY-MM-DD');

        // Calculate the difference in days
        const diffInDays = endDateToCheck.diff(today, 'days');
        if (diffInDays >= 0) {
            const endStartdiff = endDateToCheck.diff(checkStartDate, 'days');
            if (endStartdiff >= 0) {
                setError('');
                return true;
            } else {
                setError('end date cannot be less than start date');
                return false;
            }
        } else {
            setError('end date cannot be in the past');
            return false;
        }
    };

    const checkValidation = (inputValue: any, type: string) => {
        const parts = inputValue.split('|').map((part: any) => parseInt(part, 10));

        const day = parts[0];
        const month = parts[1];
        const year = parts[2];

        if (day >= 1 && day <= 31) {
            setError('');
            if (month >= 1 && month <= 12) {
                setError('');
                if (year >= 10 && year <= 99) {
                    setError('');
                } else {
                    setError(`please enter proper ${type} date formate`);
                }
            } else {
                setError(`please enter proper ${type} date formate`);
            }
        } else {
            setError(`please enter proper ${type} date formate`);
        }
    };

    const makeDateFormate = (date: any) => {
        const currentYear = `${new Date().getFullYear()}`;
        const firstTwoDigits = currentYear.substring(0, 2);
        const [day, month, yearShort] = date.split('|');
        const year = `${firstTwoDigits}${yearShort}`;
        return dateTimeFormate(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    };

    const onSaveChange = async () => {
        if (selectedValue.value !== general.BUSY) {
            formik.handleSubmit();
        } else if (value.endDate !== '' && value.startDate !== '') {
            formik.handleSubmit();
        } else {
            if (value.startDate === '') {
                setError('please enter start date');
            } else {
                setError('please enter end date');
            }
        }
    };

    const disabledCheck = () => {
        if (
            selectedValue.value !== employDetailData.status ||
            formik.values.email !== employDetailData.email ||
            formik.values.backupEmail !== employDetailData.backupEmail ||
            formik.values.phoneNumber !== `${employDetailData.countryCode}${employDetailData.phoneNumber}` ||
            formik.values.name !== employDetailData.name ||
            formik.values.surName !== employDetailData.surname ||
            formik.values.language_fluency !== employDetailData.languageFluency ||
            formik.values.jobType !== employDetailData?.jobTypeId ||
            company.id !== employDetailData?.companyId ||
            (reward === 'yes' ? true : false) !== employDetailData?.reward
        ) {
            return false;
        } else if (selectedValue.value === general.BUSY) {
            if (value.startDate !== getRemovetzTime(employDetailData?.statusRecord?.startDate, 'DD|MM|YY') || value.endDate !== getRemovetzTime(employDetailData?.statusRecord?.endDate, 'DD|MM|YY')) {
                return false;
            }
        }
        return true;
    };

    const onSubmit = async (values: any, helpers: any) => {
        const startDate = makeDateFormate(value.startDate);
        const endDate = makeDateFormate(value.endDate);
        const endDateDiff = dateCheckValidation(value.endDate, value.startDate);
        let payload;
        if (selectedValue.value !== general.BUSY) {
            payload = {
                employeeId: `/${employDetailData.employeeId}`,
                status: selectedValue.value,
                email: values.email,
                phoneNumber: number.num,
                backupEmail: values.backupEmail,
                countryCode: number.countryCode,
                countryName: number.countryName,
                name: values.name,
                surname: values.surName,
                languageFluency: values.language_fluency,
                companyId: company.id,
                jobTypeId: values.jobType,
                reward: reward === 'yes' ? true : false,
            };
        } else {
            if (startDate === 'Invalid date') {
                setError(`please enter proper start date formate`);
                return;
            } else if (endDate === 'Invalid date') {
                setError(`please enter proper end date formate`);
                return;
            } else if (!endDateDiff) {
                return;
            }
            payload = {
                employeeId: `/${employDetailData.employeeId}`,
                status: selectedValue.value,
                startDate: startDate,
                endDate: endDate,
                email: values.email,
                phoneNumber: number.num,
                backupEmail: values.backupEmail,
                countryCode: number.countryCode,
                countryName: number.countryName,
                name: values.name,
                surname: values.surName,
                languageFluency: values.language_fluency,
                companyId: company.id,
                jobTypeId: values.jobType,
                reward: reward === 'yes' ? true : false,
            };
        }
        try {
            setBtnDisabled(true);
            const res = await updateEmployee(payload);
            if (res?.status === 200) {
                onSaveChangesSuccess();
                toaster('success', res.data.message);
            } else {
                toaster('error', res.data.message);
                setErrorBackend(res.data.message);
                setBtnDisabled(false);
            }
        } catch (err: any) {
            toaster('error', err?.response.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            surName: '',
            backupEmail: '',
            language_fluency: employDetailData.languageFluency,
            jobType: employDetailData?.jobTypeId,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter name'),
            email: Yup.string().email('Please enter proper email'),
            backupEmail: Yup.string().email('Please enter proper backup email'),
            surName: Yup.string().required('Please enter surname'),
            // phoneNumber: Yup.string().required('Please enter phone number'),
        }),
        onSubmit: onSubmit,
    });
    useEffect(() => {
        disabledCheck();
    }, [formik.values]);

    const options = [
        {
            value: general.AVAILABLE,
            label: 'Available',
        },
        {
            value: general.WORKING,
            label: 'Working',
        },
        {
            value: general.UNAVAILABLE,
            label: 'Unavailable',
        },
        {
            value: general.ON_HOLD,
            label: 'On Hold',
        },
        { value: general.BUSY, label: 'Busy' },
        {
            value: general.FIRED,
            label: 'Fired',
        },
        {
            value: general.QUIT,
            label: 'Quit',
        },
    ];

    const selectFunction = (data: any) => {
        setSelectedValue(data);
        setErrorBackend('');
    };
    const GetCurrentstatus = (label: any) => {
        const statusColors: any = {
            Working: '#36B845',
            Available: '#EF3434',
            Unavailable: '#7A3CE3',
            'On Hold': '#627BE8',
            Busy: '#FFCC15',
            Fired: '#CC5500',
            Quit: 'pink',
        };
        const isColor = statusColors[label] || '';
        return isColor;
    };
    const removeEmployee = async () => {
        setModalOpen(false);
        try {
            setBtnDisabled(true);
            const res = await deleteEmployee(`?employeeId=${employDetailData.employeeId}`);
            if (res?.status === 200) {
                onSaveChangesSuccess();
                toaster('success', res.data.message);
            } else {
                toaster('error', res.data.message);
                setErrorBackend(res.data.message);
                setBtnDisabled(false);
            }
        } catch (err: any) {
            toaster('error', err?.response.message);
        }
    };
    const formatOptionLabel = ({ valued, label, customAbbreviation }: any) => (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        marginLeft: '-5px',
                        marginTop: '2px',
                        width: '25px',
                    }}
                >
                    <BsDot size={28} style={{ color: GetCurrentstatus(label) }} />
                </div>
                <div>{label}</div>
            </div>
        </>
    );
    return (
        <>
            <div className="flex -mx-4  px-4 pt-4 max-md:px-5 max-md:pt-5 max-md:pb-5 max-lg:p-5 max-lg:gap-5">
                <div
                    className={`p-4 w-full md:w-1/2 max-lg:bg-white md:p-4 max-lg:relative  max-lg:rounded-xl lg:w-1/3 px-xxl-8 lg:px-3 lg:border-r ${
                        moreDetailStepResponsive === 1 ? 'max-md:block max-md:w-full block' : 'max-md:hidden block'
                    }`}
                >
                    <div
                        className={` h-[calc(100vh-150px)] scrollbar-hide overflow-y-auto max-md:h-[calc(100vh-300px)]
                max-lg:h-[calc(100vh-200px)] ${moreDetailStepResponsive === 1 ? 'max-md:block max-md:w-full block' : 'max-md:hidden block'}`}
                    >
                        <div className="flex gap-3 mb-3 items-center max-lg:hidden">
                            {employDetailData?.reward && <img src={cupIcon} alt={'cupIcon'} />}
                            <span className="text-base font-semibold text-black">{`${employDetailData.name} ${employDetailData.surname}`}</span>
                        </div>
                        <form action="">
                            <div className="mb-3">
                                <InputBox
                                    name={'name'}
                                    type={'text'}
                                    className="bg-gray10"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        setErrorBackend('');
                                    }}
                                    onBlur={formik.handleBlur}
                                    inputId={'floating_filled_8'}
                                    value={formik.values.name}
                                    placeholder="Enter The Name"
                                    error={formik.errors.name && formik.touched.name ? formik.errors.name : ''}
                                    maxLength={validation.name}
                                />
                            </div>
                            <div className="mb-3">
                                <InputBox
                                    name={'surName'}
                                    type={'text'}
                                    className="bg-gray10"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        setErrorBackend('');
                                    }}
                                    onBlur={formik.handleBlur}
                                    inputId={'floating_filled_9'}
                                    value={formik.values.surName}
                                    placeholder="Enter The Surname"
                                    error={formik.errors.surName && formik.touched.surName ? formik.errors.surName : ''}
                                    maxLength={validation.surname}
                                />
                            </div>
                            <div className="mb-3">
                                <InputBox
                                    name={'email'}
                                    type={'email'}
                                    className="bg-gray10"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        setErrorBackend('');
                                    }}
                                    onBlur={formik.handleBlur}
                                    inputId={'floating_filled_6'}
                                    value={formik.values.email}
                                    placeholder="Enter The Email"
                                    error={formik.errors.email && formik.touched.email ? formik.errors.email : ''}
                                    maxLength={validation.email}
                                />
                            </div>
                            <div className="mb-3">
                                <InputBox
                                    name={'backupEmail'}
                                    type={'email'}
                                    className="bg-gray10"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        setErrorBackend('');
                                    }}
                                    onBlur={formik.handleBlur}
                                    inputId={'floating_filled_7'}
                                    value={formik.values.backupEmail}
                                    placeholder="Backup Email Goes here"
                                    error={formik.errors.backupEmail && formik.touched.backupEmail ? formik.errors.backupEmail : ''}
                                    maxLength={validation.email}
                                />
                            </div>
                            <div className="mb-3 employee_modal_input">
                                <PhoneInputs
                                    formik={formik}
                                    onChange={() => {
                                        setErrorBackend('');
                                    }}
                                    setNumber={setNumber}
                                    phoneInputNumber={phoneInputNumber}
                                    inputClass="employEditPhoneInput"
                                    buttonClass="employEditPhoneButton"
                                    countryName={number.countryName}
                                />
                            </div>
                        </form>
                        <div className="mb-3">
                            <h1 className="text-gray80 text-sm font-normal">English Level</h1>
                            <div className="mt-2 flex gap-4 language_radio">
                                <div>
                                    <input
                                        type="radio"
                                        name="language_fluency"
                                        value={general.BEGINNER}
                                        id={`${general.BEGINNER}-1-${uniqueId}`}
                                        checked={formik.values.language_fluency === general.BEGINNER}
                                        onChange={() => {
                                            formik.setFieldValue('language_fluency', general.BEGINNER);
                                            setErrorBackend('');
                                        }}
                                        className="mr-2"
                                    />
                                    <label className="text-xxl-base text-sm" htmlFor={`${general.BEGINNER}-1-${uniqueId}`}>
                                        Beginner
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="language_fluency"
                                        value={general.INTERMEDIATE}
                                        id={`${general.INTERMEDIATE}-1-${uniqueId}`}
                                        checked={formik.values.language_fluency === general.INTERMEDIATE}
                                        onChange={() => {
                                            formik.setFieldValue('language_fluency', general.INTERMEDIATE);
                                            setErrorBackend('');
                                        }}
                                        className="mr-2"
                                    />
                                    <label className="text-xxl-base text-sm" htmlFor={`${general.INTERMEDIATE}-1-${uniqueId}`}>
                                        Intermediate
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="language_fluency"
                                        value={general.FLUENT}
                                        id={`${general.FLUENT}-1-${uniqueId}`}
                                        checked={formik.values.language_fluency === general.FLUENT}
                                        onChange={() => {
                                            formik.setFieldValue('language_fluency', general.FLUENT);
                                            setErrorBackend('');
                                        }}
                                        className="mr-2"
                                    />
                                    <label className="text-xxl-base text-sm" htmlFor={`${general.FLUENT}-1-${uniqueId}`}>
                                        Fluent
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <h1 className="text-gray80 text-sm font-normal">Job Type</h1>
                            <div className="mt-3 flex gap-4 language_radio">
                                {listData.jobList?.map((data: any, index: number) => (
                                    <div key={index}>
                                        <input
                                            type="radio"
                                            name="jobType"
                                            value={data.id}
                                            id={`${data.name}-${index}-${uniqueId}`}
                                            checked={formik.values.jobType === data.id}
                                            onChange={() => {
                                                formik.setFieldValue('jobType', data.id);
                                                setErrorBackend('');
                                            }}
                                            className="mr-2"
                                        />
                                        <label htmlFor={`${data.name}-${index}-${uniqueId}`}>{data.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-3">
                            <h1 className="text-gray80 text-sm font-normal">Reward</h1>
                            <div className="mt-3 flex gap-4 language_radio">
                                <div>
                                    <input
                                        type="radio"
                                        name="reward"
                                        value={'yes'}
                                        id={`rewardYes-${uniqueId}`}
                                        checked={reward === 'yes'}
                                        onChange={() => {
                                            setReward('yes');
                                        }}
                                        className="mr-2"
                                    />
                                    <label htmlFor={`rewardYes-${uniqueId}`}>{'Yes'}</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="reward"
                                        value={'no'}
                                        id={`rewardNo-${uniqueId}`}
                                        checked={reward === 'no'}
                                        onChange={() => {
                                            setReward('no');
                                        }}
                                        className="mr-2"
                                    />
                                    <label htmlFor={`rewardNo-${uniqueId}`}>{'No'}</label>
                                </div>
                            </div>
                        </div>
                        <div className="my-4">
                            <div className="relative job_dropdown">
                                <SelectBox value={selectedValue} onChangeFunc={selectFunction} options={options} formatOptionLabel={formatOptionLabel} />
                                <label htmlFor="selectBox" className="absolute top-1 left-[14px] text-xs text-zinc-500 font-normal font-['Satoshi Variable']">
                                    Status
                                </label>
                            </div>
                        </div>
                        {selectedValue.value === general.BUSY && (
                            <div className="mb-2">
                                <p className="text-violet-900 text-xs font-bold font-['Satoshi Variable']">Please Specify Dates</p>
                                <div className="mt-2 flex gap-4">
                                    <div className="px-3 py-1.5 rounded-xl border border-stone-300 justify-start items-center">
                                        <div className="w-16 text-zinc-500 text-xs font-normal font-['Satoshi Variable']">Start Date</div>
                                        <InputMask mask="99|99|99" placeholder="DD|MM|YY" value={value.startDate} onChange={onStartChange} className="w-20 busy_date"></InputMask>
                                    </div>
                                    <div className="px-3 py-1.5 rounded-xl border border-stone-300 justify-start items-center">
                                        <div className="w-16 text-zinc-500 text-xs font-normal font-['Satoshi Variable']">End Date</div>
                                        <InputMask mask="99|99|99" placeholder="DD|MM|YY" value={value.endDate} onChange={onEndChange} className="w-20 busy_date"></InputMask>
                                    </div>
                                </div>
                                {error && <p className="text-red-600 mt-1 text-sm">{error}</p>}
                            </div>
                        )}
                        <div className="mb-4">
                            <p className="text-gray80 text-sm font-normal mb-2">Company</p>
                            <div className="flex gap-2">
                                {listData.companyList?.map((data: any, index: number) => (
                                    <div className="w-20" key={index}>
                                        <IconWithButton
                                            title={data?.name}
                                            icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                            isColor={data?.name === company.name ? true : false}
                                            onclick={() => {
                                                setCompany({
                                                    name: data?.name,
                                                    id: data?.id,
                                                });
                                                setErrorBackend('');
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <ul>
                            <li className="flex justify-between items-center mb-2">
                                <span className="text-gray80 text-xs font-medium">Rating:</span>
                                <span className="text-black text-xs font-medium text-right flex items-center gap-1">
                                    <BsFillStarFill />
                                    {roundedNumber(employDetailData.rating)}
                                </span>
                            </li>
                            <li className="flex justify-between items-center mb-2">
                                <span className="text-gray80 text-xs font-medium">Hours:</span>
                                <span className="text-black text-xs font-medium text-right">{convertedHours(employDetailData.hours)} hrs.</span>
                            </li>
                            <li className="flex justify-between items-center mb-2">
                                <span className="text-gray80 text-xs font-medium">Jobs:</span>
                                <span className="text-black text-xs font-medium text-right">{employDetailData.totalJobs} job</span>
                            </li>
                            <li className="flex justify-between items-center mb-2">
                                <span className="text-gray80 text-xs font-medium">Type:</span>
                                <span className="text-black text-xs font-medium text-right">{employDetailData.jobType}</span>
                            </li>
                        </ul>
                    </div>
                    <div className={`mt-3 w-full  gap-2 ${moreDetailStepResponsive === 2 ? ' max-md:hidden max-lg:flex hidden' : ' max-md:hidden max-lg:flex lg:hidden'}`}>
                        <ResponsiveTextWithIconButton title={'Save Changes'} onclick={() => onSaveChange()} isDisabled={disabledCheck() || btnDisabled} />
                        <ResponsiveTextWithIconButton title={'Remove Employee'} onclick={() => setModalOpen(true)} isDisabled={btnDisabled} />
                    </div>
                </div>
                <div
                    className={`w-full md:w-1/2 max-lg:bg-white max-lg:p-4 lg:w-2/3 lg:px-8 max-lg:rounded-xl ${
                        moreDetailStepResponsive === 2 ? 'max-md:block block max-md:w-full' : 'max-md:hidden block'
                    }`}
                >
                    <div className={`dashboard_dropdown ${isMobile < breakPoints.mobile ? 'dashboard_dropdown_responsive' : ''} w-36 max-md:w-32 mb-2`}>
                        <SelectBox value={reviewOptionValue} onChangeFunc={reviewOptionSelectFunction} options={reviewOptions} classComp="custom_react_select" menuPlacement="bottom" />
                    </div>
                    {reviewOptionValue.value === 'time_sheet' ? (
                        employDetailData?.jobs?.length === 0 ? (
                            <div className="flex justify-center items-center h-full max-md:h-[calc(100vh-230px)]">No Timesheet Record Found</div>
                        ) : (
                            <ul className="flex flex-col gap-4 h-[calc(100vh-150px)] overflow-x-auto scrollbar-hide max-md:h-[calc(100vh-230px)] max-lg:h-[calc(100vh-150px)]">
                                {employDetailData?.jobs?.map((data: any, index: number) => (
                                    <li className="w-full p-4 bg-neutral-100 rounded-2xl justify-between items-center gap-5 inline-flex flex-row max-lg:flex-wrap" key={index}>
                                        <div className="lg:w-1/3 flex-col justify-start items-start gap-1 inline-flex">
                                            <div className="text-zinc-500 text-xs font-medium">Job ID:</div>
                                            <div className="text-black text-xs font-medium">{data?.jobId?.jobConfirmation}</div>
                                        </div>
                                        <div className="lg:w-1/3">
                                            <div className="w-[88px] self-stretch pl-2 pr-2.5 py-1 bg-emerald-50 rounded-[9px] justify-center items-center gap-1 flex">
                                                <div className="text-green-500 text-xs font-medium">{convertedHoursMinutes(data?.hours, ':')} hrs</div>
                                            </div>
                                        </div>

                                        <div className="lg:flex-col justify-start items-start gap-1  flex float-right w-full lg:w-1/3">
                                            <div className="text-gray-500 text-xs font-medium max-lg:w-1/4">Time sheet:</div>
                                            <div className="text-black text-xs font-medium flex justify-end max-lg:w-3/4">
                                                {getRemovetzTime(data?.startTime, 'M/D h:mm a')}
                                                {''} - {''}
                                                {getRemovetzTime(data?.endTime, 'M/D h:mm a')}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )
                    ) : employDetailData?.reviews?.length === 0 ? (
                        <div className="flex justify-center items-center h-full max-md:h-[calc(100vh-230px)]">No Review Record Found</div>
                    ) : (
                        <ul className="flex flex-col gap-4 h-[calc(100vh-150px)] overflow-x-auto scrollbar-hide max-md:h-[calc(100vh-230px)] max-lg:h-[calc(100vh-150px)]">
                            {employDetailData?.reviews?.map((data: any, index: number) => (
                                <li className="w-full p-4 bg-neutral-100 rounded-2xl max-md:p-3" key={index}>
                                    <div className="flex justify-between items-center w-full">
                                        <div>
                                            <span className="text-zinc-500 text-xs font-medium mr-2 max-md:mr-1">Job ID:</span>
                                            <span className="text-black text-xs font-medium">{data?.jobId?.jobConfirmation}</span>
                                        </div>
                                        <div className="star-review">
                                            <StarRatings
                                                rating={data?.rate}
                                                numberOfStars={5}
                                                name={`rating-${index}`}
                                                starDimension="20px"
                                                starSpacing={`${isMobile < breakPoints.mobile ? '2px' : '4px'}`}
                                                starRatedColor="#627BE8"
                                                starEmptyColor="#F6F6F8"
                                                svgIconViewBox="0 0 12 11"
                                                svgIconPath="M5.44146 1.08156C5.59114 0.620904 6.24284 0.620902 6.39252 1.08156L7.01306 2.99139C7.21387 3.60943 7.78981 4.02786 8.43965 4.02786H10.4478C10.9321 4.02786 11.1335 4.64767 10.7417 4.93237L9.11705 6.11271C8.59132 6.49468 8.37134 7.17173 8.57215 7.78976L9.19269 9.69959C9.34237 10.1602 8.81512 10.5433 8.42327 10.2586L6.79867 9.07827C6.27294 8.6963 5.56104 8.69631 5.03531 9.07827L3.41071 10.2586C3.01886 10.5433 2.49162 10.1603 2.6413 9.69959L3.26184 7.78976C3.46265 7.17173 3.24266 6.49468 2.71693 6.11271L1.09233 4.93237C0.700475 4.64767 0.90186 4.02786 1.38622 4.02786H3.39434C4.04418 4.02786 4.62011 3.60942 4.82092 2.99139L5.44146 1.08156Z"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <textarea
                                            value={data?.review}
                                            rows={3}
                                            cols={50}
                                            className="h-12 w-full text-zinc-500 text-xs font-medium scrollbar-default"
                                            style={{ resize: 'none', outline: 'none' }}
                                            disabled={true}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <div className=" w-full px-4 -mx-4 max-lg:hidden block">
                <button
                    type="button"
                    className={` text-purple80 text-xs font-medium ${disabledCheck() || btnDisabled ? 'opacity-40' : ''}`}
                    onClick={onSaveChange}
                    disabled={disabledCheck() || btnDisabled}
                >
                    Save Changes
                </button>
                <button
                    type="button"
                    className={` text-purple80 text-xs font-medium ml-16 ${btnDisabled ? 'opacity-40' : ''}`}
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    disabled={btnDisabled}
                >
                    Remove Employee
                </button>
            </div>
            <div className={` w-full  gap-2 ${moreDetailStepResponsive === 2 ? 'hidden' : 'max-md:flex hidden'}`}>
                <ResponsiveTextWithIconButton title={'Employee Time-Sheets'} onclick={() => setMoreDetailStepResponsive(2)} isDisabled={btnDisabled} />
            </div>
            <div className={`mt-3 mb-2 w-full  gap-2 ${moreDetailStepResponsive === 2 ? 'hidden' : 'max-md:flex hidden'}`}>
                <ResponsiveTextWithIconButton title={'Save Changes'} onclick={() => onSaveChange()} isDisabled={disabledCheck() || btnDisabled} />
                <ResponsiveTextWithIconButton title={'Remove Employee'} onclick={() => setModalOpen(true)} isDisabled={btnDisabled} />
            </div>
            {errorBackend && <p className="text-red-600 mt-1 text-sm">{errorBackend}</p>}
            {modalOpen && (
                <ModalContentBlock onclose={() => setModalOpen(false)} isMoreClass="logout_modal" isModalOpen={modalOpen}>
                    <ConfirmationPopUp
                        confirmText="Do you really want to remove the employee?"
                        submitOnClick={() => {
                            removeEmployee();
                        }}
                        cancelOnClick={() => setModalOpen(false)}
                    />
                </ModalContentBlock>
            )}
        </>
    );
};

export default EmployeeDetail;
