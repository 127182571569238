import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import HeaderContentBlock from 'src/components/Common/HeaderContentBlock';
import SortFilterContentModal from 'src/components/Common/SortFilterContentModal';
import { BsDot } from 'react-icons/bs';
import { employeeDetailById, employeeList } from 'src/services/employee';
import logImg from 'src/assets/img/logImg.png';
import flagImg from 'src/assets/img/flagImg.png';
import AddNewEmployee from 'src/components/Employee/AddNewEmployee';
import westImg from 'src/assets/img/westImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import ModalContentBlock from 'src/components/Common/ModalContentBlock';
import { Paginator } from 'primereact/paginator';
import EmployeeSortFilter from 'src/components/Employee/EmployeeSortFilter';
import EmployeeDetail from 'src/components/Employee/EmployeeDetail';
import { GoStar } from 'react-icons/go';
import { convertedHours, roundedNumber } from 'src/utils/function';
import cupIcon from 'src/assets/svgs/cup.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import ResponsiveHeaderContentBlock from 'src/components/Responsive/ResponsiveHeaderContentBlock';
import ResponsiveEmployeeListAccordion from 'src/components/Responsive/employee/ResponsiveEmployeeListAccordion';
import blueCancelIcon from 'src/assets/svgsResponsive/blueCancelIcon.svg';
import blueLeftlIcon from 'src/assets/svgsResponsive/blueLeftArrow.svg';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { breakPoints } from 'src/constants/general';

const Employee = () => {
    const { isMobile }: any = useDeviceDetect();
    const allListData = useSelector((state: RootState) => state.getCommonApi);
    const [sortModal, setSortModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [addNewEmploy, setAddNewEmploy] = useState(false);
    const [employListData, setEmployListData] = useState([]);
    const [totalEmployRecords, setTotalEmployRecords] = useState(0);
    const [isSave, setIsSave] = useState(false);
    const [first, setFirst] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [filterApply, setFilterApply] = useState(0);
    const [rows, setRows] = useState(9);
    const [page, setPage] = useState(0);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const controller = new AbortController();
    const [openAcoordionResponsive, setOpenAcoordionResponsive] = useState<any>(null);
    const [moreDetailStepResponsive, setMoreDetailStepResponsive] = useState(0);
    const [selectedValue, setSelectedValue] = useState({
        value: '',
        label: 'All',
    });
    const [reviewOptionValue, setReviewOptionValue] = useState({ value: 'time_sheet', label: 'Time Sheet' });
    const [employDetailData, setEmployDetailData] = useState<any>({});
    const [sortBy, setSortBy] = useState({
        date: { name: 'Newest', value: 'latest' },
        jobType: { name: 'All', id: '' },
        rating: { name: 'Highest', value: 'highest' },
        company: { name: 'All', id: '' },
    });
    const [listData, setListData] = useState<{
        jobList: any[];
        companyList: any[];
    }>({
        jobList: [],
        companyList: [],
    });
    useEffect(() => {
        getJobCompanyList();
    }, [allListData?.companyData, allListData?.jobTypeData]);

    useEffect(() => {
        if (searchInput && filterApply) {
            getEmployList(
                `?page=${page}&limit=${rows}&search=${searchInput}&sortByDate=${sortBy.date.value}&jobTypeId=${sortBy.jobType.id}&sortByRating=${sortBy.rating.value}&status=${selectedValue.value}&companyId=${sortBy.company.id}`,
                controller.signal,
            );
        } else if (searchInput) {
            getEmployList(`?page=${page}&limit=${rows}&search=${searchInput}`, controller.signal);
        } else if (filterApply) {
            getEmployList(
                `?page=${page}&limit=${rows}&sortByDate=${sortBy.date.value}&jobTypeId=${sortBy.jobType.id}&sortByRating=${sortBy.rating.value}&status=${selectedValue.value}&companyId=${sortBy.company.id}`,
                controller.signal,
            );
        } else {
            getEmployList(`?page=${page}&limit=${rows}`, controller.signal);
        }
        return () => controller.abort();
    }, [page, rows, searchInput, filterApply]);

    const getJobCompanyList = async () => {
        setListData((prevData) => ({
            ...prevData,
            companyList: allListData?.companyData,
        }));
        setListData((prevData) => ({
            ...prevData,
            jobList: allListData?.jobTypeData,
        }));
        setSortBy({
            date: { name: 'All', value: '' },
            jobType: {
                name: 'All',
                id: '',
            },
            rating: { name: 'All', value: '' },
            company: { name: 'All', id: '' },
        });
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.page);
    };

    const getEmployList = async (url: string, signal?: any) => {
        const res = await employeeList(url, signal);
        setEmployListData(res?.data?.data?.employees);
        setTotalEmployRecords(res?.data?.data?.total);
    };

    const onRowClick = async (event: any) => {
        await getEmployDetailData(event.data.employeeId);
        setModalOpen(true);
        setMoreDetailStepResponsive(1);
    };

    const getEmployDetailData = async (id: any) => {
        const employDetail = await employeeDetailById(`/${id}`);
        setEmployDetailData(employDetail?.data?.data);
    };
    const handleSearch = (e: any) => {
        setPage(0);
        setSearchInput(e.target.value);
    };
    const handleFilterClear = () => {
        setPage(0);
        setSortModal(false);
        setFilterApply(0);
        setSortBy({
            date: { name: 'All', value: '' },
            jobType: {
                name: 'All',
                id: '',
            },
            rating: { name: 'All', value: '' },
            company: { name: 'All', id: '' },
        });
        setSelectedValue({
            value: '',
            label: 'All',
        });
    };
    const handleFilterApply = () => {
        setPage(0);
        setFilterApply(filterApply + 1);
        setSortModal(false);
    };

    const GetIconWithText1 = (row: any) => (
        <div className="flex items-center gap-1">
            <img className="w-6 h-6 rounded-full" src={row.companyName === 'West' ? westImg : row.companyName === 'Log' ? logImg : fsImg} alt="img" />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{row.companyName}</div>
        </div>
    );
    const GetName = (row: any) => (
        <div className="gap-1">
            <div className="flex items-center gap-2 mb-1">
                {row?.reward && <img src={cupIcon} alt={'cupIcon'} />}
                <p className="text-zinc-800 text-sm font-medium font-['Satoshi Variable'] leading-none">{`${row.name} ${row.surname}`}</p>
            </div>
            <p className="text-[#808080] text-xs font-medium font-['Satoshi Variable']">{row.email}</p>
        </div>
    );
    const GetIconWithText = (row: any) => (
        <div className="flex items-center gap-1">
            <img className="w-6 h-6 rounded-full" src={flagImg} alt="img" />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{row.languageFluency}</div>
        </div>
    );
    const GetRating = (row: any) => (
        <div className="flex items-center gap-1">
            <GoStar />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{roundedNumber(row.rating)}</div>
        </div>
    );
    const GetHours = (row: any) => <div className="">{convertedHours(row.hours)}</div>;
    const GetRatingHeader = () => (
        <div className="flex items-center gap-1">
            <GoStar />
            <div className="">{'Rating'}</div>
        </div>
    );
    const GetCurrentstatus = (row: any) => {
        const statusColors: any = {
            Working: 'bg-Green20 text-Green80',
            Available: 'bg-red20 text-red500',
            Unavailable: 'bg-purpleLight text-purple80',
            'On Hold': 'bg-secondary20 text-secondary',
            Busy: 'bg-yellow20 text-yellow80',
            Fired: 'bg-orange-100 text-orange-600',
            Quit: 'bg-pink-100 text-pink-600',
        };

        const isColor = statusColors[row.status] || '';

        return (
            <div className={`h-6 pe-2 ps-1 min-w-24 py-1 ${isColor} rounded-lg justify-center items-center inline-flex`}>
                <div>
                    <BsDot size={18} />
                </div>
                <div className={`text-xs font-medium`}>{row.status}</div>
            </div>
        );
    };
    const newEmployeeSuccess = () => {
        if (page === 0) {
            newAddOrUpdateEmployee(true);
        } else {
            setPage(0);
            setFirst(0);
        }
        setAddNewEmploy(false);
        setBtnDisabled(false);
    };

    const newAddOrUpdateEmployee = (isNewAdd = false) => {
        if (searchInput && filterApply) {
            getEmployList(
                `?page=${isNewAdd ? 0 : page}&limit=${rows}&search=${searchInput}&sortByDate=${sortBy.date.value}&jobTypeId=${sortBy.jobType.id}&sortByRating=${sortBy.rating.value}&status=${
                    selectedValue.value
                }&companyId=${sortBy.company.id}`,
            );
        } else if (searchInput) {
            getEmployList(`?page=${isNewAdd ? 0 : page}&limit=${rows}&search=${searchInput}`);
        } else if (filterApply) {
            getEmployList(
                `?page=${isNewAdd ? 0 : page}&limit=${rows}&sortByDate=${sortBy.date.value}&jobTypeId=${sortBy.jobType.id}&sortByRating=${sortBy.rating.value}&status=${
                    selectedValue.value
                }&companyId=${sortBy.company.id}`,
            );
        } else {
            getEmployList(`?page=${isNewAdd ? 0 : page}&limit=${rows}`);
        }
    };

    return (
        <div>
            <div className={`${moreDetailStepResponsive ? 'max-lg:flex hidden' : 'hidden'} justify-between items-center`}>
                <span
                    onClick={() => {
                        if (moreDetailStepResponsive === 1) {
                            setModalOpen(false);
                            setMoreDetailStepResponsive(0);
                        } else {
                            setMoreDetailStepResponsive(1);
                        }
                    }}
                >
                    <img src={blueLeftlIcon} alt="blueLeftlIcon" className="cursor-pointer max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
                </span>

                <div className="flex gap-3  items-center justify-center max-md:w-[68.53%] max-lg:w-[69%]">
                    {employDetailData?.reward && <img src={cupIcon} alt={'cupIcon'} />}
                    <span className="text-base font-semibold text-black">{`${employDetailData?.name} ${employDetailData?.surname}`}</span>
                </div>
                <span
                    onClick={() => {
                        setModalOpen(false);
                        setMoreDetailStepResponsive(0);
                    }}
                >
                    <img src={blueCancelIcon} alt="blueCancelIcon" className="cursor-pointer  max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
                </span>
            </div>
            <div className={`${moreDetailStepResponsive ? 'hidden' : 'max-lg:block hidden'}`}>
                <ResponsiveHeaderContentBlock
                    sortOnClick={() => setSortModal(true)}
                    closeOnClick={() => setAddNewEmploy(false)}
                    addNewOnClick={() => setAddNewEmploy(true)}
                    isDisabled={addNewEmploy}
                    searchOnchange={handleSearch}
                    searchInput={searchInput}
                    isNext={addNewEmploy}
                />
            </div>
            <HeaderContentBlock
                sortOnClick={() => setSortModal(true)}
                closeOnClick={() => setAddNewEmploy(false)}
                saveOnClick={() => setIsSave(true)}
                addNewOnClick={() => setAddNewEmploy(true)}
                nextStep={addNewEmploy}
                addNewBtnText={'Add new Employee'}
                isEmploy={true}
                headerText="Employees"
                isDisabled={btnDisabled}
                searchOnchange={handleSearch}
                searchInput={searchInput}
            />
            {addNewEmploy && (
                <AddNewEmployee
                    setIsSave={setIsSave}
                    isSave={isSave}
                    listData={listData}
                    isOnSuccess={() => {
                        newEmployeeSuccess();
                    }}
                    setBtnDisabled={setBtnDisabled}
                    btnDisabled={btnDisabled}
                />
            )}
            <div className={`pt-5 pb-0 w-full ${addNewEmploy || moreDetailStepResponsive ? 'hidden' : 'max-lg:block hidden'}`}>
                <>
                    {employListData?.length === 0 && (
                        <div
                            className=" bg-white max-md:h-10 flex justify-center items-center max-lg:font-semibold
                         max-lg:text-xl max-lg:h-12  max-md:font-medium max-md:text-lg"
                        >
                            No Employee Record Found
                        </div>
                    )}
                    <ResponsiveEmployeeListAccordion
                        employListData={employListData}
                        openAcoordionResponsive={openAcoordionResponsive}
                        setOpenAcoordionResponsive={setOpenAcoordionResponsive}
                        GetCurrentstatus={GetCurrentstatus}
                        onRowClick={onRowClick}
                    />
                    <div className="mt-5">
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={totalEmployRecords}
                            onPageChange={onPageChange}
                            className="datatable_pagination"
                            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        />
                    </div>
                </>
            </div>
            {moreDetailStepResponsive !== 0 && isMobile < breakPoints.tablet && (
                <div className={`${moreDetailStepResponsive ? 'max-lg:block hidden' : 'hidden'}`}>
                    <EmployeeDetail
                        employDetailData={employDetailData}
                        onSaveChangesSuccess={() => {
                            newAddOrUpdateEmployee();
                            setModalOpen(false);
                            setMoreDetailStepResponsive(0);
                        }}
                        listData={listData}
                        uniqueId={'mob-tab'}
                        moreDetailStepResponsive={moreDetailStepResponsive}
                        setMoreDetailStepResponsive={setMoreDetailStepResponsive}
                        reviewOptionValue={reviewOptionValue}
                        setReviewOptionValue={setReviewOptionValue}
                    />
                </div>
            )}
            <div className="py-6 block max-lg:hidden">
                <DataTable value={employListData} tableClassName="logdatatable" onRowClick={onRowClick} emptyMessage="No Employee Record Found" className="">
                    <Column field="company" header="Company" body={GetIconWithText1}></Column>
                    <Column field="name" header="Name" body={GetName}></Column>
                    <Column field="jobType" header="Job type"></Column>
                    <Column field="languagefluency" header="Language fluency" body={GetIconWithText}></Column>
                    <Column field="totalJobs" header="Jobs #"></Column>
                    <Column field="hours" header="Hours #" body={GetHours}></Column>
                    <Column field="rating" header={GetRatingHeader} body={GetRating}></Column>
                    <Column field="currentstatus" header="Current status" body={GetCurrentstatus}></Column>
                </DataTable>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalEmployRecords}
                    onPageChange={onPageChange}
                    className="datatable_pagination"
                    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                />
            </div>
            {sortModal && (
                <SortFilterContentModal setSortModal={setSortModal} onFilterClear={handleFilterClear} onFilterApply={handleFilterApply} isJob={true} isModalOpen={sortModal}>
                    <EmployeeSortFilter selectedValue={selectedValue} setSelectedValue={setSelectedValue} listData={listData} sortBy={sortBy} setSortBy={setSortBy} />
                </SortFilterContentModal>
            )}
            {modalOpen && isMobile >= breakPoints.tablet && (
                <div className={`${modalOpen ? 'max-lg:hidden block' : 'hidden'}`}>
                    <ModalContentBlock
                        onclose={() => {
                            setModalOpen(false);
                            setMoreDetailStepResponsive(0);
                        }}
                        isMoreClass={'w-[60%]'}
                        isModalOpen={modalOpen}
                    >
                        <EmployeeDetail
                            employDetailData={employDetailData}
                            onSaveChangesSuccess={() => {
                                newAddOrUpdateEmployee();
                                setModalOpen(false);
                                setMoreDetailStepResponsive(0);
                            }}
                            listData={listData}
                            uniqueId={'desktop'}
                            moreDetailStepResponsive={moreDetailStepResponsive}
                            setMoreDetailStepResponsive={setMoreDetailStepResponsive}
                            reviewOptionValue={reviewOptionValue}
                            setReviewOptionValue={setReviewOptionValue}
                        />
                    </ModalContentBlock>
                </div>
            )}
        </div>
    );
};
export default Employee;
