/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import lessThanIcon from 'src/assets/svgs/lessthanIcon.svg';
import { PATH } from 'src/constants/path';
import SidebarContentBlock from 'src/components/Common/SidebarContentBlock';
import TextWithIconButton from 'src/components/Common/TextWithIconButton';
import circleIcon from 'src/assets/svgs/plusCircleIcon.svg';
import blackCircleIcon from 'src/assets/svgs/blackPlusCircleIcon.svg';
import starIcon from 'src/assets/svgs/starIcon.svg';
import flagImg from 'src/assets/img/flagImg.png';
import editIcon from 'src/assets/svgs/editIcon.svg';
import rightIcon from 'src/assets/svgs/rightIcon.svg';
import History from 'src/components/JobDetailInfo/History';
import blueLeftlIcon from 'src/assets/svgsResponsive/blueLeftArrow.svg';
import bluePlusCircleIcon from 'src/assets/svgsResponsive/bluePlusCircleIcon.svg';
import ModalContentBlock from 'src/components/Common/ModalContentBlock';
import { jobDetailList, updateInvoice, updateJob } from 'src/services/jobDetail';
import {
    checkDifference,
    checkImageOrNot,
    checkIsPastDate,
    convertedHours,
    convertedHoursMinutes,
    dateTimeFormate,
    getCurrentDateTime,
    getRemovetzTime,
    roundedNumber,
    tokenDecode,
    utcConverter,
} from 'src/utils/function';
import moment from 'moment';
import { toaster } from 'src/utils/toaster';
import JobCreateStep2 from 'src/components/Job/JobCreateStep2';
import TimeSheet from 'src/components/JobDetailInfo/TimeSheet';
import EditTimeSheet from 'src/components/JobDetailInfo/EditTimeSheet';
import FinishJob from 'src/components/JobDetailInfo/FinishJob';
import { BsFillStarFill } from 'react-icons/bs';
import { RootState } from 'src/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { RxCross2 } from 'react-icons/rx';
import { requestorDataAsync } from 'src/redux/reducers/getCommonApiSlice';
import ConfirmationPopUp from 'src/components/Common/ConfirmationPopUp';
import { deleteJob } from 'src/services/job';
import uploadIcon from 'src/assets/svgs/uploadIcon.svg';
import Loader from 'src/components/Common/Loader';
import { breakPoints, generalConstants } from 'src/constants/general';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
interface ValueObject {
    startDate: Date;
    endDate: Date | null;
    startTime: string;
    endTime: string;
}

const JobDetailInfo = () => {
    const { isMobile }: any = useDeviceDetect();
    const allListData = useSelector((state: RootState) => state.getCommonApi);
    const dispatch = useDispatch();
    const filterSearch = useSelector((state: RootState) => state.jobFilter);
    const [historyBtn, setHistoryBtn] = useState(false);
    const [modalOpen, setModalOpen] = useState('');
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [jobRemoveModalOpen, setJobRemoveModalOpen] = useState(false);
    const navigate = useNavigate();
    const Location = useLocation();
    const [error, setError] = useState({ jobConfirm: '', date: '' });
    const [detailList, setDetailList] = useState<any>([]);
    const [timeSheetList, setTimeSheetList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [historyTimeSheetList, sethistoryTimeSheetList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [selectEmployee, setSelectEmployee] = useState([]);
    const [initialSelectEmployee, setInitialSelectEmployee] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [currentUpdateData, setCurrentUpdateData] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [invoiceEmployeeId, setInvoiceEmployeeId] = useState(0);
    const [fullView, setFullView] = useState(false);
    const [file, setFile] = useState('');
    const [userData, setUserData] = useState<any>({});
    const [resendInvoicedData, setResendInvoicedData] = useState([]);
    const [emptyTimeSheetError, setEmptyTimeSheetError] = useState('');
    const [responsiveScreen, setResponsiveScreen] = useState('activitylist');
    const [listData, setListData] = useState<{
        jobList: any[];
        companyList: any[];
        locationList: any[];
        requestorList: any[];
    }>({
        jobList: [],
        companyList: [],
        locationList: [],
        requestorList: [],
    });
    const [step1Data, setStep1Data] = useState({
        companyType: {
            name: '',
            id: 0,
        },
        jobType: { name: '', id: 0 },
        jobConfirmation: '',
        workers: '1',
        requestor: '',
        location: '',
        startDate: '',
        endDate: '',
        requestorName: '',
        selectedDate: [],
        startOfPrevWeek: '',
        endOfNextWeek: '',
        value: {
            startDate: new Date(),
            endDate: null,
            startTime: '',
            endTime: '',
        } as ValueObject,
        notes: '',
        department: '',
        status: '',
        asapStatus: false,
        mandatoryStatus: false,
    });
    const assignEmployee = 'assignEmployee';
    const addTimeSheet = 'addTimeSheet';
    const finishJob = 'finishJob';
    const editTimeSheet = 'editTimeSheet';
    const isStatus = ['Done', 'Cancelled'];
    const minutes = 2;
    const getToken = async () => {
        const token = await tokenDecode();
        setUserData(token);
    };
    useEffect(() => {
        getToken();
    }, []);
    useEffect(() => {
        getJobDetail();
    }, []);
    useEffect(() => {
        getJobCompanyList();
    }, [allListData?.companyData, allListData?.jobTypeData, allListData?.locationData, allListData?.requestorData]);
    useEffect(() => {
        if (historyList.length !== 0 || timeSheetList.length !== 0) {
            const initArr = [...historyList, ...timeSheetList];
            initArr.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            sethistoryTimeSheetList(initArr);
        }
    }, [historyList, timeSheetList]);
    useEffect(() => {
        if (
            step1Data.companyType.id === detailList?.companyId &&
            step1Data.jobType.id === detailList?.jobTypeId &&
            step1Data.jobConfirmation === detailList?.jobConfirmation &&
            Number(step1Data.workers) === detailList?.workers &&
            Number(step1Data.requestor) === detailList?.requestorId &&
            Number(step1Data.location) === detailList?.locationId &&
            step1Data.startDate === convertDate(detailList?.startDate) &&
            step1Data.endDate === (detailList?.endDate ? convertDate(detailList?.endDate) : '') &&
            step1Data.notes === detailList?.note &&
            step1Data.asapStatus === detailList?.isAsap &&
            step1Data.mandatoryStatus === detailList?.isMandatory
        ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [step1Data]);

    const getNewDate = (date: any, formate: string) => {
        const newDate = new Date(date);
        return dateTimeFormate(newDate, formate);
    };
    useEffect(() => {
        if (startDate) {
            const formattedNewStartDate = getNewDate(startDate, 'YYYY-MM-DD');
            const formattedNewEndDate = endDate ? getNewDate(endDate, 'YYYY-MM-DD') : getNewDate(startDate, 'YYYY-MM-DD');
            const startDateDiff = moment(formattedNewStartDate); // Assuming format YYYY-MM-DD
            const endDateDiff = moment(formattedNewEndDate);

            // Calculate difference in days
            const dateDifference = endDateDiff.diff(startDateDiff, 'days');

            // Get all dates between start and end date
            const allDates: any = [];
            for (let i = 0; i <= dateDifference; i++) {
                const currentDate = startDateDiff.clone().add(i, 'days');
                allDates.push(currentDate.format('YYYY-MM-DD'));
            }
            if (allDates.length !== 0) {
                setStep1Data((prevData: any) => ({
                    ...prevData,
                    selectedDate: allDates,
                }));
            }

            const startOfWeek = startDateDiff.clone().startOf('week');
            const formateStartOfWeek = startOfWeek.format('YYYY-MM-DD');
            const endOfWeek = startDateDiff.clone().endOf('week');
            const formateEndOfWeek = endOfWeek.format('YYYY-MM-DD');

            // Calculate the start and end dates of the previous week
            const startOfPrevWeek = startOfWeek.clone().subtract(1, 'week');
            // const formateStartOfPrevWeek = startOfPrevWeek.format('YYYY-MM-DD');

            const endOfNextWeek = endOfWeek.clone().add(1, 'week');
            // const formateEndOfNextWeek = endOfNextWeek.format('YYYY-MM-DD');

            if (startOfPrevWeek && endOfNextWeek) {
                setStep1Data((prevData: any) => ({
                    ...prevData,
                    startOfPrevWeek: formateStartOfWeek,
                    endOfNextWeek: formateEndOfWeek,
                }));
            }
        }
    }, [startDate, endDate]);
    const convertDate = (date: string) => getRemovetzTime(date, 'YYYY-MM-DD HH:mm:ss');

    const foramteNewDate = (isDate: any) => new Date(getRemovetzTime(isDate, 'YYYY-MM-DD HH:mm:ss'));

    const formateNewTime = (isDate: any) => getRemovetzTime(isDate, 'HH:mm');

    const getDefaultValue = () => {
        setStep1Data((prevData) => ({
            ...prevData,
            location: `${detailList?.locationId}`,
            requestor: detailList?.requestorId,
            requestorName: detailList?.requestorName,
            jobType: {
                name: detailList?.jobType,
                id: detailList?.jobTypeId,
            },
            companyType: {
                name: detailList?.companyName,
                id: detailList?.companyId,
            },
            jobConfirmation: detailList?.jobConfirmation,
            workers: `${detailList?.workers}`,
            value: {
                startDate: foramteNewDate(detailList?.startDate),
                endDate: detailList?.endDate ? foramteNewDate(detailList?.endDate) : null,
                startTime: formateNewTime(detailList?.startDate),
                endTime: detailList?.endDate ? formateNewTime(detailList?.endDate) : '',
            },
            notes: detailList?.note,
            department: detailList?.departmentId,
            status: detailList?.status,
            startDate: convertDate(detailList?.startDate),
            endDate: detailList?.endDate ? convertDate(detailList?.endDate) : '',
            asapStatus: detailList?.isAsap,
            mandatoryStatus: detailList?.isMandatory,
        }));
        const filteredEmployeeIds = detailList?.employees?.map((data: any) => data.employeeId);
        setSelectEmployee(filteredEmployeeIds);
        setError(() => ({
            jobConfirm: '',
            date: '',
        }));
    };
    const getJobDetail = async () => {
        const jobDetail = await jobDetailList(`/${Location.state.id}`);
        if (jobDetail?.data?.data?.updateInvoiced?.length !== 0) {
            const sortUpdateInvoicedData = jobDetail?.data?.data?.updateInvoiced?.sort((a: any, b: any) => {
                // First, compare employee_id.id
                const aEmployeeId = a.employee_id.id;
                const bEmployeeId = b.employee_id.id;

                if (aEmployeeId < bEmployeeId) {
                    return -1;
                }
                if (aEmployeeId > bEmployeeId) {
                    return 1;
                }

                // If employee_id.id is the same, compare createdAt in descending order
                const aCreatedAt = new Date(a.createdAt);
                const bCreatedAt = new Date(b.createdAt);

                if (aCreatedAt > bCreatedAt) {
                    return -1;
                }
                if (aCreatedAt < bCreatedAt) {
                    return 1;
                }

                // If both employee_id.id and createdAt are the same, return 0
                return 0;
            });
            setResendInvoicedData(sortUpdateInvoicedData);
        }
        setDetailList(jobDetail?.data?.data);
        setTimeSheetList(jobDetail?.data?.data?.timeSheets);
        setHistoryList(jobDetail?.data?.data?.jobHistory.reverse());
        setInvoiceList(jobDetail?.data?.data?.invoiced);
        setStep1Data((prevData) => ({
            ...prevData,
            location: `${jobDetail?.data?.data?.locationId}`,
            requestor: jobDetail?.data?.data?.requestorId,
            requestorName: jobDetail?.data?.data?.requestorName,
            jobType: {
                name: jobDetail?.data?.data?.jobType,
                id: jobDetail?.data?.data?.jobTypeId,
            },
            companyType: {
                name: jobDetail?.data?.data?.companyName,
                id: jobDetail?.data?.data?.companyId,
            },
            jobConfirmation: jobDetail?.data?.data?.jobConfirmation,
            workers: `${jobDetail?.data?.data?.workers}`,
            value: {
                startDate: foramteNewDate(jobDetail?.data?.data?.startDate),
                endDate: jobDetail?.data?.data?.endDate ? foramteNewDate(jobDetail?.data?.data?.endDate) : null,
                startTime: formateNewTime(jobDetail?.data?.data?.startDate),
                endTime: jobDetail?.data?.data?.endDate ? formateNewTime(jobDetail?.data?.data?.endDate) : '',
            },
            notes: jobDetail?.data?.data?.note,
            department: jobDetail?.data?.data?.departmentId,
            status: jobDetail?.data?.data?.status,
            startDate: convertDate(jobDetail?.data?.data?.startDate),
            endDate: jobDetail?.data?.data?.endDate ? convertDate(jobDetail?.data?.data?.endDate) : '',
            asapStatus: jobDetail?.data?.data?.isAsap,
            mandatoryStatus: jobDetail?.data?.data?.isMandatory,
        }));
        setStartDate(foramteNewDate(jobDetail?.data?.data?.startDate));
        setEndDate(jobDetail?.data?.data?.endDate ? foramteNewDate(jobDetail?.data?.data?.endDate) : null);
        const filteredEmployeeIds = jobDetail?.data?.data?.employees?.map((data: any) => data.employeeId);
        setSelectEmployee(filteredEmployeeIds);
        setInitialSelectEmployee(filteredEmployeeIds);
        setBtnDisabled(false);
        setInvoiceEmployeeId(0);
    };

    const getJobCompanyList = async () => {
        setListData((prevData) => ({
            ...prevData,
            companyList: allListData?.companyData,
            jobList: allListData?.jobTypeData,
            locationList: allListData?.locationData,
            requestorList: allListData?.requestorData,
        }));
    };
    const checkValidation = () => {
        if (!step1Data.jobConfirmation) {
            setError((prevData) => ({
                ...prevData,
                jobConfirm: 'please enter confirmation number',
            }));
            return true;
        } else if (step1Data.jobConfirmation?.length < 10) {
            setError((prevData) => ({
                ...prevData,
                jobConfirm: 'please enter last 5 digit confirmation number',
            }));
            return true;
        }
        return false;
    };
    const getRequestor = async () => {
        if (!step1Data.requestor) {
            dispatch(requestorDataAsync());
        }
    };
    const getUpdateJob = async (type?: string) => {
        const validate = checkValidation();
        if (validate) {
            return;
        }
        if (type !== 'jobUpdate') {
            if (initialSelectEmployee.length === selectEmployee.length) {
                const abc = initialSelectEmployee.filter((value) => !selectEmployee.includes(value));
                if (abc.length === 0) {
                    setModalOpen('');
                    setResponsiveScreen('activitylist');
                    return;
                }
            }
        } else if (step1Data.endDate) {
            if (!checkDifference(step1Data.startDate, step1Data.endDate, minutes)) {
                setError((prevData) => ({
                    ...prevData,
                    date: 'end time cannot be less than start time',
                }));
                return;
            }
        }
        const payload = {
            jobDetailId: `/${Location.state.id}`,
            companyId: step1Data.companyType.id,
            jobTypeId: step1Data.jobType.id,
            jobConfirmation: step1Data.jobConfirmation,
            workers: Number(step1Data.workers),
            requestorId: !step1Data.requestor ? undefined : Number(step1Data.requestor),
            requestorName: step1Data.requestorName,
            departmentId: step1Data.department,
            locationId: Number(step1Data.location),
            startDate: utcConverter(step1Data.startDate, 'YYYY-MM-DD HH:mm:ss'),
            endDate: step1Data.endDate ? utcConverter(step1Data.endDate, 'YYYY-MM-DD HH:mm:ss') : null,
            employees: selectEmployee,
            note: step1Data.notes,
            isAsap: step1Data.asapStatus,
            isMandatory: step1Data.mandatoryStatus,
        };
        setBtnDisabled(true);
        const updateJobDetail = await updateJob(payload);
        if (updateJobDetail?.status === 200) {
            toaster('success', updateJobDetail.data.message);
            setModalOpen('');
            setResponsiveScreen('activitylist');
            getJobDetail();
            if (type === 'jobUpdate') {
                getRequestor();
            }
        } else {
            toaster('error', updateJobDetail.data.message);
        }
    };

    const addTimeSheetSuccess = () => {
        setModalOpen('');
        setResponsiveScreen('activitylist');
        getJobDetail();
        setEmptyTimeSheetError('');
    };

    const updateTimeSheetSuccess = () => {
        setModalOpen('');
        setResponsiveScreen('activitylist');
        getJobDetail();
    };

    const editOnclick = (data: any) => {
        setCurrentUpdateData(data);
        setModalOpen(editTimeSheet);
        setResponsiveScreen(editTimeSheet);
    };

    const jobFinishSuccess = () => {
        setModalOpen('');
        setResponsiveScreen('activitylist');
        getJobDetail();
    };
    const cancelJob = async () => {
        const payload = {
            jobDetailId: `/${Location.state.id}`,
            endDate: getCurrentDateTime('YYYY-MM-DD HH:mm:ss'),
            isCancel: true,
        };
        const updateJobDetail = await updateJob(payload);
        if (updateJobDetail?.status === 200) {
            toaster('success', updateJobDetail.data.message);
            setCancelModalOpen(false);
            getJobDetail();
        } else {
            toaster('error', updateJobDetail.data.message);
        }
    };
    const removeJob = async () => {
        setJobRemoveModalOpen(false);
        try {
            const res = await deleteJob(`?jobId=${Location.state.id}`);
            if (res?.status === 200) {
                toaster('success', res.data.message);
                navigate(PATH.JOB, { state: { filterSearch: filterSearch.filterApply !== 0 || filterSearch.searchInput !== '' ? true : false } });
            } else {
                toaster('error', res.data.message);
            }
        } catch (err: any) {
            toaster('error', err?.response.message);
        }
    };
    const handleFileChange = async (event: any, empId: any) => {
        if (event.target.files[0]) {
            setInvoiceEmployeeId(empId);
            setBtnDisabled(true);
            const formData = new FormData();
            formData.append('jobId', detailList?.id);
            formData.append('employeeId', empId);
            formData.append('images', event.target.files[0]);

            const resInvoice = await updateInvoice(formData);
            if (resInvoice.status === 200) {
                getJobDetail();
            } else {
                toaster('error', resInvoice.data.message);
                setBtnDisabled(false);
                setInvoiceEmployeeId(0);
            }
        }
    };
    const isBack = () => {
        setModalOpen('');
        setResponsiveScreen('activitylist');
        setBtnDisabled(false);
    };
    return (
        <div className="bg-lightgray">
            <div className="w-full pl-8 h-20 pr-12 py-2 bg-white rounded-b-[32px] flex items-center max-lg:hidden">
                <button
                    className="w-10 h-10 p-4 bg-black rounded-3xl justify-between items-center inline-flex max-lg:hidden"
                    onClick={() => {
                        navigate(PATH.JOB, { state: { filterSearch: filterSearch.filterApply !== 0 || filterSearch.searchInput !== '' ? true : false } });
                    }}
                >
                    <img src={lessThanIcon} alt="lessthanicon" className="w-2 h-4" />
                </button>
                <div className="w-full text-center">
                    <h4 className="text-black text-2xl font-black font-['Satoshi Variable']">Detailed Info</h4>
                </div>
            </div>
            <div className="lg:px-6 lg:pt-6 lg:pb-4  layout_main min-h-screen ">
                <div
                    className={`justify-between items-center  px-4 pt-4
                ${
                    isMobile < breakPoints.mobile
                        ? responsiveScreen === 'activitylist'
                            ? 'max-lg:flex'
                            : 'hidden'
                        : isMobile < breakPoints.tablet
                        ? responsiveScreen === 'activitylist' || responsiveScreen === 'jobDetails'
                            ? 'max-lg:flex'
                            : 'hidden'
                        : 'hidden'
                }
                `}
                >
                    <span
                        onClick={() => {
                            navigate(PATH.JOB, { state: { filterSearch: filterSearch.filterApply !== 0 || filterSearch.searchInput !== '' ? true : false } });
                        }}
                    >
                        <img src={blueLeftlIcon} alt="blueLeftlIcon" className="cursor-pointer max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
                    </span>

                    <div className="flex gap-3  items-center justify-center max-md:w-[68.53%] max-lg:w-[69%]">
                        {isStatus.includes(detailList?.status) ? (
                            <p className="text-base font-semibold text-black">Employee History</p>
                        ) : (
                            <>
                                <p className="text-base font-semibold text-black">Assigned Employees</p>
                                <button
                                    className={`${checkIsPastDate(step1Data.startDate, step1Data.endDate) ? 'opacity-40' : ''}`}
                                    onClick={() => {
                                        getDefaultValue();
                                        setModalOpen(assignEmployee);
                                        setResponsiveScreen(assignEmployee);
                                    }}
                                    disabled={checkIsPastDate(step1Data.startDate, step1Data.endDate)}
                                >
                                    <img src={blackCircleIcon} alt="circleicon" className="h-4 w-4" />
                                </button>
                            </>
                        )}
                    </div>
                    <span
                        onClick={() => {
                            if (['Cancelled'].includes(detailList?.status)) {
                                return;
                            } else {
                                getDefaultValue();
                                setModalOpen(addTimeSheet);
                                setResponsiveScreen(addTimeSheet);
                            }
                        }}
                    >
                        <img src={bluePlusCircleIcon} alt="bluePlusCircleIcon" className="cursor-pointer  max-md:w-[38px] max-md:h-[38px] max-lg:w-[50px] max-lg:h-[50px]" />
                    </span>
                </div>
                <div className="p-8 max-lg:p-4 pt-4 flex rounded-3xl max-lg:gap-5 lg:bg-white">
                    {historyBtn && (
                        <div
                            className="lg:w-[210px] lg:border-r lg:mr-6 max-lg:bg-white max-lg:rounded-2xl max-lg:p-4 max-lg:w-full
                        "
                        >
                            <History setHistoryBtn={setHistoryBtn} historyList={historyList} isBackHistory={() => setResponsiveScreen('jobDetails')} />
                        </div>
                    )}
                    <div
                        className={`max-md:w-full max-lg:w-1/2 max-lg:rounded-xl max-lg:p-5 lg:w-1/4 xl:w-1/5 pr-6 max-lg:bg-white lg:border-r
                    ${
                        isMobile < breakPoints.mobile
                            ? responsiveScreen === 'jobDetails'
                                ? 'block'
                                : 'hidden'
                            : isMobile < breakPoints.tablet
                            ? responsiveScreen === 'activitylist' || responsiveScreen === 'jobDetails'
                                ? 'block'
                                : 'hidden'
                            : 'block'
                    }
                    `}
                    >
                        <SidebarContentBlock
                            isDetailInfo={true}
                            setHistoryBtn={setHistoryBtn}
                            listData={listData}
                            setListData={setListData}
                            step1Data={step1Data}
                            setStep1Data={setStep1Data}
                            error={error}
                            setError={setError}
                            updateJob={() => getUpdateJob('jobUpdate')}
                            isDisabled={isDisabled || btnDisabled}
                            setResponsiveScreen={setResponsiveScreen}
                        />
                    </div>
                    <div
                        className={`pl-6 xl:pl-10 lg:flex-1 max-lg:rounded-xl max-md:w-full max-lg:w-1/2 max-lg:bg-white max-lg:p-5
                    ${
                        isMobile < breakPoints.mobile
                            ? responsiveScreen === 'activitylist'
                                ? 'block'
                                : 'hidden'
                            : isMobile < breakPoints.tablet
                            ? responsiveScreen === 'activitylist' || responsiveScreen === 'jobDetails'
                                ? 'block'
                                : 'hidden'
                            : 'block'
                    }`}
                    >
                        <div className="flex items-center justify-between">
                            <div className="flex gap-2 items-center max-lg:hidden">
                                {isStatus.includes(detailList?.status) ? (
                                    <p className="text-black font-black font-['Satoshi Variable']">Employee History</p>
                                ) : (
                                    <>
                                        <p className="text-black font-black font-['Satoshi Variable']">Assigned Employees</p>
                                        <button
                                            className={`${checkIsPastDate(step1Data.startDate, step1Data.endDate) ? 'opacity-40' : ''}`}
                                            onClick={() => {
                                                getDefaultValue();
                                                setModalOpen(assignEmployee);
                                                setResponsiveScreen(assignEmployee);
                                            }}
                                            disabled={checkIsPastDate(step1Data.startDate, step1Data.endDate)}
                                        >
                                            <img src={blackCircleIcon} alt="circleicon" className="h-4 w-4" />
                                        </button>
                                    </>
                                )}
                            </div>
                            {detailList?.length !== 0 && (
                                <div className="flex gap-4 items-center justify-center max-lg:hidden">
                                    <TextWithIconButton
                                        btnClassName="btn-secondary font-medium px-6 py-4 uppercase"
                                        textName="Remove Job"
                                        onClick={() => {
                                            setJobRemoveModalOpen(true);
                                            getDefaultValue();
                                        }}
                                    />
                                    {['Cancelled'].includes(detailList?.status) ? (
                                        ''
                                    ) : (
                                        <TextWithIconButton
                                            btnClassName="btn-secondary font-medium px-6 py-4 uppercase"
                                            icon={circleIcon}
                                            textName="Add time sheet"
                                            altName="circleicon"
                                            onClick={() => {
                                                getDefaultValue();
                                                setModalOpen(addTimeSheet);
                                                setResponsiveScreen(addTimeSheet);
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        {emptyTimeSheetError && <div className="text-red-600 text-base font-medium text-right mt-2 max-lg:text-center">{emptyTimeSheetError}</div>}
                        {isStatus.includes(detailList?.status) || userData?.userType === generalConstants.superAdmin || true ? (
                            <div className="lg:mt-4 h-[680px] overflow-auto job_history">
                                {historyTimeSheetList?.length !== 0 && (
                                    <div className={`bg-white border rounded-md px-4 flex justify-between items-center shadow mb-4 me-1`}>
                                        <div className="flex gap-2 py-[6px] lg:items-center max-lg:flex-col max-lg:gap-0">
                                            <p className="text-gray80 text-sm font-normal">{detailList?.userId?.name}</p>
                                            <p className="text-sm font-normal">{'Create The Job'}</p>
                                        </div>
                                        <h4 className=" text-sm font-normal">{getRemovetzTime(detailList?.createdAt, 'DD.MM.YYYY')}</h4>
                                    </div>
                                )}
                                {historyTimeSheetList?.map(
                                    (data: any, index: number) =>
                                        data?.activity !== '' && (
                                            <div
                                                className={`${
                                                    data?.employeeDetail ? 'bg-lightgray py-2 rounded-lg' : 'bg-white border rounded-md'
                                                } px-4 flex justify-between items-center shadow mb-4 me-1 max-lg:gap-3 max-lg:flex-wrap`}
                                                key={index}
                                            >
                                                {data?.employeeDetail ? (
                                                    <>
                                                        <div>
                                                            <p className="text-black text-xs font-medium font-['Satoshi Variable']">{`${data.employeeDetail.name} ${data.employeeDetail.surname}`}</p>
                                                            <div className="flex gap-3">
                                                                <p className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">
                                                                    Jobs:
                                                                    <span className="text-black text-xs font-normal font-['Satoshi Variable']">{data.employeeDetail.totalJobs}</span>
                                                                </p>
                                                                <p className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">
                                                                    Hrs:
                                                                    <span className="text-black text-xs font-normal font-['Satoshi Variable']">{convertedHours(data.employeeDetail.hours)}</span>
                                                                </p>
                                                                <div className="flex gap-2">
                                                                    <img src={starIcon} alt="staricon" />
                                                                    <span className="text-black text-xs font-normal font-['Satoshi Variable']">{roundedNumber(data.employeeDetail.rating)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-2">
                                                                <div>
                                                                    <img src={flagImg} alt="staricon" className="h-3 w-3" />
                                                                </div>
                                                                <p className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">{data.employeeDetail.languageFluency}</p>
                                                            </div>
                                                        </div>
                                                        {data?.id ? (
                                                            <div className="flex flex-col  items-end lg:hidden">
                                                                <div className="w-20 h-9 pl-2 pr-2.5 py-1 bg-emerald-50 rounded-lg justify-center items-center gap-1 inline-flex">
                                                                    <div className="text-green-500 text-xs font-medium font-['Satoshi Variable']">{convertedHoursMinutes(data.hours, ':')} hrs</div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {data?.id ? (
                                                            <div className="flex items-center gap-4 max-lg:w-full  lg:justify-end">
                                                                <div className="flex flex-col lg:w-1/2 items-end max-lg:hidden">
                                                                    <div className="w-20 h-9 pl-2 pr-2.5 py-1 bg-emerald-50 rounded-lg justify-center items-center gap-1 inline-flex">
                                                                        <div className="text-green-500 text-xs font-medium font-['Satoshi Variable']">{convertedHoursMinutes(data.hours, ':')} hrs</div>
                                                                    </div>
                                                                </div>
                                                                <div className=" w-[240px] max-lg:w-1/2">
                                                                    <p className="text-gray-500 text-xs font-medium font-['Satoshi Variable']">Time sheet:</p>
                                                                    <p className="text-black text-xs font-medium font-['Satoshi Variable']">{`${getRemovetzTime(
                                                                        data.startTime,
                                                                        'M/D h:mm a',
                                                                    )} - ${getRemovetzTime(data.endTime, 'M/D h:mm a')}`}</p>
                                                                </div>
                                                                {['Cancelled'].includes(detailList?.status) ? (
                                                                    <div className="flex items-center gap-2 cursor-pointer max-lg:w-1/2 justify-end">
                                                                        <img src={editIcon} alt="staricon" />
                                                                        <span className="text-indigo-500 text-xs font-bold font-['Satoshi Variable']">Edit</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex items-center gap-2 cursor-pointer max-lg:w-1/2 justify-end" onClick={() => editOnclick(data)}>
                                                                        <img src={editIcon} alt="staricon" />
                                                                        <span className="text-indigo-500 text-xs font-bold font-['Satoshi Variable']">Edit</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="">
                                                                <p className="text-gray-500 text-xs font-medium font-['Satoshi Variable']">
                                                                    Time sheet:
                                                                    <span className="text-black text-xs font-medium font-['Satoshi Variable'] ml-10">N/A</span>
                                                                </p>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="flex gap-2 py-[6px] lg:items-center max-lg:flex-col max-lg:gap-0">
                                                            <p className="text-gray80 text-sm font-normal">{data?.userId?.name}</p>
                                                            <p className="text-sm font-normal">{data?.activity}</p>
                                                        </div>
                                                        <h4 className=" text-sm font-normal">{getRemovetzTime(data?.activityTime, 'DD.MM.YYYY')}</h4>
                                                    </>
                                                )}
                                            </div>
                                        ),
                                )}

                                {resendInvoicedData?.length !== 0 &&
                                    resendInvoicedData?.map(
                                        (data: any, index: number) =>
                                            data?.invoicedUrl &&
                                            data?.user_id && (
                                                <div
                                                    className={`bg-lightgray py-2 rounded-lg px-4 flex justify-between lg:items-center shadow mb-4 me-1 max-lg:flex  max-lg:flex-row max-lg:gap-2`}
                                                    key={index}
                                                >
                                                    <div className="max-lg:flex max-lg:flex-col max-lg:w-1/2  max-lg:justify-between max-lg:gap-3 ">
                                                        <p className="text-sm font-normal">{`${data?.employee_id?.name} ${data?.employee_id?.surname}`}</p>
                                                        <div className="flex max-lg:flex-col lg:gap-2 lg:py-[2px] lg:items-center ">
                                                            <p className="text-gray80 text-sm font-normal">{`${data?.user_id?.name}`}</p>
                                                            <p className="text-sm font-normal">{'Re-send invoiced'}</p>
                                                        </div>
                                                    </div>
                                                    {!data?.isLast ? (
                                                        <div className="flex gap-11 max-lg:items-end items-center  flex-row max-lg:flex-col max-lg:gap-2 max-lg:w-1/2 max-lg:justify-between">
                                                            {checkImageOrNot(data?.invoice_type) ? (
                                                                <div
                                                                    className="cursor-pointer "
                                                                    onClick={() => {
                                                                        setFile(data?.invoicedUrl);
                                                                        setFullView(true);
                                                                    }}
                                                                >
                                                                    <img src={data?.invoicedUrl} alt="" height={80} width={80} className="max-lg:h-10 max-lg:w-16 h-16 w-28 rounded-lg" />
                                                                </div>
                                                            ) : (
                                                                <div className="cursor-pointer justify-end flex">
                                                                    <a href={data?.invoicedUrl} target="_blank" rel="noopener noreferrer" className="text-secondary underline">
                                                                        {'View Invoice'}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="flex gap-11 max-lg:items-end items-center  flex-row max-lg:flex-col max-lg:gap-2 max-lg:w-1/2 max-lg:justify-between">
                                                            {checkImageOrNot(data?.invoice_type) ? (
                                                                btnDisabled && invoiceEmployeeId === data?.employee_id?.id ? (
                                                                    <div>
                                                                        <Loader color={'#000000'} loading={btnDisabled} size={35} />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="cursor-pointer "
                                                                        onClick={() => {
                                                                            setFile(data?.invoicedUrl);
                                                                            setFullView(true);
                                                                        }}
                                                                    >
                                                                        <img src={data?.invoicedUrl} alt="" height={80} width={80} className="max-lg:h-10 max-lg:w-16 h-16 w-28 rounded-lg" />
                                                                    </div>
                                                                )
                                                            ) : btnDisabled && invoiceEmployeeId === data?.employee_id?.id ? (
                                                                <div>
                                                                    <Loader color={'#000000'} loading={btnDisabled} size={35} />
                                                                </div>
                                                            ) : (
                                                                <div className="cursor-pointer">
                                                                    <a href={data?.invoicedUrl} target="_blank" rel="noopener noreferrer" className="text-secondary underline">
                                                                        {'View Invoice'}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            <div>
                                                                <label
                                                                    htmlFor={`file-input-invoice-${data?.employee_id?.id}`}
                                                                    className={`flex items-center justify-center ${btnDisabled ? '' : 'cursor-pointer'} gap-1`}
                                                                >
                                                                    <img src={uploadIcon} alt="uploadImage" className="h-5 w-6 cursor-pointer" />
                                                                    <p className="text-sm text-secondary">Change Invoice</p>
                                                                </label>
                                                                <input
                                                                    id={`file-input-invoice-${data?.employee_id?.id}`}
                                                                    type="file"
                                                                    accept=""
                                                                    className="hidden"
                                                                    onChange={(e) => handleFileChange(e, data?.employee_id?.id)}
                                                                    disabled={btnDisabled}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                    )}

                                {invoiceList?.map(
                                    (data: any, index: number) =>
                                        data?.invoicedUrl &&
                                        data?.create_invoice_id && (
                                            <div className={`bg-lightgray py-2 rounded-lg px-4 flex justify-between lg:items-center shadow mb-4 me-1 max-lg:gap-3 max-lg:flex-row`} key={index}>
                                                <div className="max-lg:flex max-lg:flex-col max-lg:w-1/2 max-lg:gap-2">
                                                    <p className="text-sm font-normal">{`${data?.employeeId?.name} ${data?.employeeId?.surname}`}</p>
                                                    <div className="flex max-lg:flex-col lg:gap-2 lg:py-[2px] lg:items-center">
                                                        <p className="text-gray80 text-sm font-normal">{`${data?.create_invoice_id?.name}`}</p>
                                                        <p className="text-sm font-normal">{'send invoiced'}</p>
                                                    </div>
                                                </div>
                                                {resendInvoicedData?.length !== 0 && resendInvoicedData.some((invoiceListData: any) => invoiceListData?.employee_id?.id === data?.employeeId?.id) ? (
                                                    <div className="flex gap-11 max-lg:items-end items-center  flex-row max-lg:flex-col max-lg:gap-2 max-lg:w-1/2 max-lg:justify-between">
                                                        {checkImageOrNot(data?.invoice_type) ? (
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    setFile(data?.invoicedUrl);
                                                                    setFullView(true);
                                                                }}
                                                            >
                                                                <img src={data?.invoicedUrl} alt="" height={80} width={80} className="max-lg:h-10 max-lg:w-16 h-16 w-28 rounded-lg" />
                                                            </div>
                                                        ) : (
                                                            <div className="cursor-pointer">
                                                                <a href={data?.invoicedUrl} target="_blank" rel="noopener noreferrer" className="text-secondary underline">
                                                                    {'View Invoice'}
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="flex gap-11 max-lg:items-end items-center  flex-row max-lg:flex-col max-lg:gap-2 max-lg:w-1/2 max-lg:justify-between">
                                                        {checkImageOrNot(data?.invoice_type) ? (
                                                            btnDisabled && invoiceEmployeeId === data?.employeeId?.id ? (
                                                                <div>
                                                                    <Loader color={'#000000'} loading={btnDisabled} size={35} />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        setFile(data?.invoicedUrl);
                                                                        setFullView(true);
                                                                    }}
                                                                >
                                                                    <img src={data?.invoicedUrl} alt="" height={80} width={80} className="max-lg:h-10 max-lg:w-16 h-16 w-28 rounded-lg" />
                                                                </div>
                                                            )
                                                        ) : btnDisabled && invoiceEmployeeId === data?.employeeId?.id ? (
                                                            <div>
                                                                <Loader color={'#000000'} loading={btnDisabled} size={35} />
                                                            </div>
                                                        ) : (
                                                            <div className="cursor-pointer">
                                                                <a href={data?.invoicedUrl} target="_blank" rel="noopener noreferrer" className="text-secondary underline">
                                                                    {'View Invoice'}
                                                                </a>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <label
                                                                htmlFor={`file-input-invoice-${data?.employeeId?.id}`}
                                                                className={`flex items-center justify-center ${btnDisabled ? '' : 'cursor-pointer'} gap-1`}
                                                            >
                                                                <img src={uploadIcon} alt="uploadImage" className="h-5 w-6 cursor-pointer" />
                                                                <p className="text-sm text-secondary">Change Invoice</p>
                                                            </label>
                                                            <input
                                                                id={`file-input-invoice-${data?.employeeId?.id}`}
                                                                type="file"
                                                                accept=""
                                                                className="hidden"
                                                                onChange={(e) => handleFileChange(e, data?.employeeId?.id)}
                                                                disabled={btnDisabled}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                )}
                            </div>
                        ) : (
                            <div className="mb-4 h-[680px] overflow-auto job_history">
                                {timeSheetList?.map((data: any, index: number) => (
                                    <div className="bg-lightgray px-4 py-2 flex max-lg:gap-3 max-lg:flex-wrap justify-between items-center rounded-lg shadow mb-4 me-1 " key={index}>
                                        <div className="">
                                            <p className="text-black text-xs font-medium font-['Satoshi Variable'] mb-1">{`${data.employeeDetail.name} ${data.employeeDetail.surname}`}</p>
                                            <div className="flex items-center gap-3 mb-1">
                                                <p className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">
                                                    Jobs:
                                                    <span className="text-black text-xs font-normal font-['Satoshi Variable'] ms-1">{data.employeeDetail.totalJobs}</span>
                                                </p>
                                                <p className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">
                                                    Hrs:
                                                    <span className="text-black text-xs font-normal font-['Satoshi Variable'] ms-1">{convertedHours(data.employeeDetail.hours)}</span>
                                                </p>
                                                <div className="flex items-center gap-1">
                                                    <BsFillStarFill className="h-3 w-3 text-gray80 mb-[2px]" />
                                                    <span className="text-black text-xs font-normal font-['Satoshi Variable']">{roundedNumber(data.employeeDetail.rating)}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-2">
                                                <div>
                                                    <img src={flagImg} alt="staricon" className="h-3 w-3" />
                                                </div>
                                                <p className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">{data.employeeDetail.languageFluency}</p>
                                            </div>
                                        </div>
                                        {data?.id ? (
                                            <div className="flex flex-col  items-end lg:hidden">
                                                <div className="w-20 h-9 pl-2 pr-2.5 py-1 bg-emerald-50 rounded-lg justify-center items-center gap-1 inline-flex">
                                                    <div className="text-green-500 text-xs font-medium font-['Satoshi Variable']">{convertedHoursMinutes(data.hours, ':')} hrs</div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {data?.id ? (
                                            <div className="flex items-center gap-4 max-lg:w-full  lg:justify-end">
                                                <div className="flex flex-col lg:w-1/2 items-end max-lg:hidden">
                                                    <div className="w-20 h-9 pl-2 pr-2.5 py-1 bg-emerald-50 rounded-lg justify-center items-center gap-1 inline-flex">
                                                        <div className="text-green-500 text-xs font-medium font-['Satoshi Variable']">{convertedHoursMinutes(data.hours, ':')} hrs</div>
                                                    </div>
                                                </div>
                                                <div className=" w-[240px] max-lg:w-1/2">
                                                    <p className="text-gray-500 text-xs font-medium font-['Satoshi Variable']">Time sheet:</p>
                                                    <p className="text-black text-xs font-medium font-['Satoshi Variable']">{`${getRemovetzTime(data.startTime, 'M/D h:mm a')} - ${getRemovetzTime(
                                                        data.endTime,
                                                        'M/D h:mm a',
                                                    )}`}</p>
                                                </div>
                                                <div className="flex items-center gap-2 cursor-pointer max-lg:w-1/2 justify-end" onClick={() => editOnclick(data)}>
                                                    <img src={editIcon} alt="staricon" />
                                                    <span className="text-indigo-500 text-xs font-bold font-['Satoshi Variable']">Edit</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="">
                                                <p className="text-gray-500 text-xs font-medium font-['Satoshi Variable']">
                                                    Time sheet:
                                                    <span className="text-black text-xs font-medium font-['Satoshi Variable'] ml-10">N/A</span>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {invoiceList?.map(
                                    (data: any, index: number) =>
                                        data?.invoicedUrl &&
                                        data?.create_invoice_id && (
                                            <div className={`bg-lightgray py-2 rounded-lg px-4 flex justify-between items-center shadow mb-4 me-1`} key={index}>
                                                <div>
                                                    <p className="text-sm font-normal">{`${data?.employeeId?.name} ${data?.employeeId?.surname}`}</p>
                                                    <div className="flex gap-2 py-[2px] items-center">
                                                        <p className="text-gray80 text-sm font-normal">{`${data?.create_invoice_id?.name}`}</p>
                                                        <p className="text-sm font-normal">{'send invoiced'}</p>
                                                    </div>
                                                </div>
                                                <div className="flex gap-11 items-center max-lg:gap-0 max-lg:w-1/2 max-lg:flex-col max-lg:justify-between">
                                                    {checkImageOrNot(data?.invoice_type) ? (
                                                        btnDisabled && invoiceEmployeeId === data?.employeeId?.id ? (
                                                            <div>
                                                                <Loader color={'#000000'} loading={btnDisabled} size={35} />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    setFile(data?.invoicedUrl);
                                                                    setFullView(true);
                                                                }}
                                                            >
                                                                <img src={data?.invoicedUrl} alt="" height={80} width={80} className="max-lg:h-10 max-lg:w-16 h-16 w-28 rounded-lg" />
                                                            </div>
                                                        )
                                                    ) : btnDisabled && invoiceEmployeeId === data?.employeeId?.id ? (
                                                        <div>
                                                            <Loader color={'#000000'} loading={btnDisabled} size={35} />
                                                        </div>
                                                    ) : (
                                                        <div className="cursor-pointer">
                                                            <a href={data?.invoicedUrl} target="_blank" rel="noopener noreferrer" className="text-secondary underline">
                                                                {'View Invoice'}
                                                            </a>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <label
                                                            htmlFor={`file-input-invoice-${data?.employeeId?.id}`}
                                                            className={`flex items-center justify-center ${btnDisabled ? '' : 'cursor-pointer'} gap-1`}
                                                        >
                                                            <img src={uploadIcon} alt="uploadImage" className="h-5 w-6 cursor-pointer" />
                                                            <p className="text-sm text-secondary">Change Invoice</p>
                                                        </label>
                                                        <input
                                                            id={`file-input-invoice-${data?.employeeId?.id}`}
                                                            type="file"
                                                            accept=""
                                                            className="hidden"
                                                            onChange={(e) => handleFileChange(e, data?.employeeId?.id)}
                                                            disabled={btnDisabled}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                )}
                            </div>
                        )}
                        {detailList?.length !== 0 && (
                            <div className=" flex max-lg:flex-col flex-row max-lg:w-full items-center justify-end gap-4 pt-5">
                                {isStatus.includes(detailList?.status) ? (
                                    ''
                                ) : (
                                    <>
                                        <button
                                            onClick={() => {
                                                setCancelModalOpen(true);
                                                getDefaultValue();
                                            }}
                                            className="lg:w-46 max-lg:w-full h-12 px-6 py-4 bg-[#FDEBEB] rounded-3xl  justify-center items-center gap-2 inline-flex border-2 border-[#8F1F1F]"
                                        >
                                            <div className="text-[#8F1F1F]">
                                                <RxCross2 />
                                            </div>
                                            <p className="text-[#8F1F1F] text-sm font-bold font-['Satoshi Variable'] uppercase">MArk as Cancelled</p>
                                        </button>
                                        <div className="max-lg:w-full">
                                            <TextWithIconButton
                                                btnClassName="lg:w-46 max-lg:w-full h-12 px-6 py-4 bg-Green80 rounded-3xl shadow justify-center items-center gap-2 inline-flex"
                                                textClassName="text-white text-sm font-bold font-['Satoshi Variable'] uppercase"
                                                imgClassName="w-6 h-6 relative"
                                                icon={rightIcon}
                                                textName="Finish the job"
                                                altName="righticon"
                                                onClick={() => {
                                                    const someTimeSheetPresent = detailList?.timeSheets?.some((sheet: any) => sheet?.id);
                                                    if (someTimeSheetPresent) {
                                                        getDefaultValue();
                                                        setModalOpen(finishJob);
                                                        setResponsiveScreen(finishJob);
                                                    } else {
                                                        setEmptyTimeSheetError("Please fill in at least one employee's timesheet first.");
                                                        return;
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {detailList?.length !== 0 && (
                    <div
                        className={`max-lg:w-full px-6 mb-2 flex-row gap-2
                    ${
                        isMobile < breakPoints.mobile
                            ? responsiveScreen === 'activitylist'
                                ? 'max-lg:flex'
                                : 'hidden'
                            : isMobile < breakPoints.tablet
                            ? responsiveScreen === 'activitylist' || responsiveScreen === 'jobDetails'
                                ? 'max-lg:flex'
                                : 'hidden'
                            : 'hidden'
                    }
                    `}
                    >
                        <TextWithIconButton
                            btnClassName="btn-secondary font-medium px-6 py-4 uppercase w-full h-12 rounded-3xl shadow justify-center items-center flex "
                            textName="Remove Job"
                            onClick={() => {
                                setJobRemoveModalOpen(true);
                                getDefaultValue();
                            }}
                        />
                        <TextWithIconButton
                            btnClassName="btn-secondary md:hidden font-medium px-6 py-4 uppercase w-full h-12 rounded-3xl shadow justify-center items-center flex "
                            textName="Job Details"
                            onClick={() => {
                                setResponsiveScreen('jobDetails');
                                getDefaultValue();
                            }}
                        />
                    </div>
                )}
                {modalOpen && isMobile < breakPoints.tablet && (
                    <div className={`${modalOpen ? 'max-lg:block hidden max-lg:px-4 ' : 'hidden'}`}>
                        {modalOpen === assignEmployee && (
                            <JobCreateStep2
                                step1Data={step1Data}
                                selectEmployee={selectEmployee}
                                setSelectEmployee={setSelectEmployee}
                                isButton={true}
                                assignEmployee={() => getUpdateJob()}
                                setBtnDisabled={setBtnDisabled}
                                isBack={isBack}
                            />
                        )}
                        {modalOpen === addTimeSheet && <TimeSheet detailList={detailList} isSelectedDate={step1Data?.selectedDate} addTimeSheetSuccess={() => addTimeSheetSuccess()} isBack={isBack} />}
                        {modalOpen === editTimeSheet && (
                            <EditTimeSheet
                                detailList={detailList}
                                currentUpdateData={currentUpdateData}
                                isSelectedDate={step1Data?.selectedDate}
                                updateTimeSheetSuccess={() => updateTimeSheetSuccess()}
                                isBack={isBack}
                            />
                        )}
                        {modalOpen === finishJob && <FinishJob detailList={detailList} jobFinishSuccess={() => jobFinishSuccess()} isBack={isBack} />}
                    </div>
                )}
            </div>

            {modalOpen && isMobile >= breakPoints.tablet && (
                <ModalContentBlock
                    onclose={() => {
                        setModalOpen('');
                        setResponsiveScreen('activitylist');
                        setBtnDisabled(false);
                    }}
                    isMoreClass={modalOpen === finishJob ? 'finish_job' : modalOpen === assignEmployee ? 'choose_employee' : ''}
                    isModalOpen={modalOpen}
                >
                    {modalOpen === assignEmployee && (
                        <JobCreateStep2
                            step1Data={step1Data}
                            selectEmployee={selectEmployee}
                            setSelectEmployee={setSelectEmployee}
                            isButton={true}
                            assignEmployee={() => getUpdateJob()}
                            setBtnDisabled={setBtnDisabled}
                        />
                    )}
                    {modalOpen === addTimeSheet && <TimeSheet detailList={detailList} isSelectedDate={step1Data?.selectedDate} addTimeSheetSuccess={() => addTimeSheetSuccess()} />}
                    {modalOpen === editTimeSheet && (
                        <EditTimeSheet detailList={detailList} currentUpdateData={currentUpdateData} isSelectedDate={step1Data?.selectedDate} updateTimeSheetSuccess={() => updateTimeSheetSuccess()} />
                    )}
                    {modalOpen === finishJob && <FinishJob detailList={detailList} jobFinishSuccess={() => jobFinishSuccess()} />}
                </ModalContentBlock>
            )}
            {cancelModalOpen && (
                <ModalContentBlock onclose={() => setCancelModalOpen(false)} isMoreClass="logout_modal" isModalOpen={cancelModalOpen}>
                    <ConfirmationPopUp
                        confirmText="Do you really want to cancel the job?"
                        submitOnClick={() => {
                            getDefaultValue();
                            cancelJob();
                        }}
                        cancelOnClick={() => setCancelModalOpen(false)}
                    />
                </ModalContentBlock>
            )}
            {jobRemoveModalOpen && (
                <ModalContentBlock onclose={() => setJobRemoveModalOpen(false)} isMoreClass="logout_modal" isModalOpen={jobRemoveModalOpen}>
                    <ConfirmationPopUp
                        confirmText="Do you really want to remove the job?"
                        submitOnClick={() => {
                            removeJob();
                        }}
                        cancelOnClick={() => setJobRemoveModalOpen(false)}
                    />
                </ModalContentBlock>
            )}
            {fullView && (
                <ModalContentBlock onclose={() => setFullView(false)} isMoreClass="modal_fullview" isModalOpen={fullView}>
                    <div className="h-full w-full flex items-center justify-center">
                        <img src={file} alt="liveimage" className="max-h-full max-w-full" />
                    </div>
                </ModalContentBlock>
            )}
        </div>
    );
};
export default JobDetailInfo;
