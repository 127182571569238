import React from 'react';
import { BsDot } from 'react-icons/bs';
import { generalConstants } from 'src/constants/general';
import { getRemovetzTime, getUpDownRoundedNumber } from 'src/utils/function';

const TopRequestor = ({ peopleOverViewData, userData }: any) => (
    <>
        <div className="flex items-center justify-between mb-4 px-4">
            <h5 className="text-black80 text-xxl-base text-sm font-medium">Top Requestor</h5>
            <p className="text-gray80 text-xxl-xs text-[11px] font-normal">{`${getRemovetzTime(peopleOverViewData?.filterStartDate, 'DD MMM')} - ${getRemovetzTime(
                peopleOverViewData?.filterEndDate,
                'DD MMM, YYYY',
            )}`}</p>
        </div>
        <div className="job_history overflow-y-auto max-h-[180px] px-4 flex-1">
            {peopleOverViewData?.topRequestors?.length !== 0 ? (
                peopleOverViewData?.topRequestors?.map((data: any, index: number) => (
                    <div className="p-4 rounded-2xl border border-gray10 mb-2" key={index}>
                        <div className="flex justify-between items-center">
                            <div>
                                <h5 className="text-black80 text-sm font-medium">{data.requestorName}</h5>
                                <div className="flex mt-2 gap-2">
                                    <div className="w-14 h-6 bg-gray10 text-black80 text-[11px] font-medium rounded-3xl border border-gray10 justify-center items-center gap-4 inline-flex">
                                        <div>{data.companyName}</div>
                                    </div>
                                    <div className=" w-[68px] h-6 bg-gray10 text-black80 text-[11px] font-medium rounded-3xl border border-gray10 justify-center items-center gap-4 inline-flex">
                                        <div>{data.jobType}</div>
                                    </div>
                                </div>
                                {userData?.userType === generalConstants.superAdmin && (
                                    <div className="flex mt-1 gap-4 text-sm items-center">
                                        <span className="flex items-center text-black80 text-sm font-normal">
                                            <BsDot />
                                            {data.jobType}
                                        </span>{' '}
                                        <span className="text-black text-sm font-normal">{`${getUpDownRoundedNumber(Number(data.workingHours))} Hrs.`}</span>
                                    </div>
                                )}
                            </div>
                            <div>
                                <h5 className="text-gray100 text-xs font-medium mb-[2px]">Jobs Received</h5>
                                <p className="text-black80 text-2xl font-bold text-center">{data.totalJobsRecieved}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="emptyMessageShow">No Records Found</div>
            )}
        </div>
    </>
);

export default TopRequestor;
