import React from 'react';
import westImg from 'src/assets/img/westImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import logImg from 'src/assets/img/logImg.png';
import upDirectionAerrow from 'src/assets/svgs/upDirectionAerrow.svg';
import downDirectionAerrow from 'src/assets/svgs/downDirectionAerrow.svg';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { BsStar, BsTrophy } from 'react-icons/bs';
import { convertedHours, roundedNumber } from 'src/utils/function';
import flagImg from 'src/assets/img/flagImg.png';

const ResponsiveActiveAvailabelListAccordion = ({ employListData, openAcoordionResponsive, setOpenAcoordionResponsive }: any) => {
    const { isMobile }: any = useDeviceDetect();
    return (
        <>
            {employListData?.length !== 0 &&
                employListData?.map((data: any, index: number) => (
                    <div key={index} className={`${index === 0 ? '' : 'mt-3'}`}>
                        <div className={`w-full flex p-[10px] bg-white flex-col rounded-3xl ${openAcoordionResponsive === index ? '' : 'border-b-2 border-[#C1C1C1]'}`}>
                            <button className={`flex items-center justify-between w-full`} onClick={() => setOpenAcoordionResponsive((preavState: any) => (preavState === index ? null : index))}>
                                <div className="flex items-center gap-2 w-1/3">
                                    <img
                                        className="max-md:w-6 max-md:h-6 max-lg:w-[26px] max-lg:h-[26px] rounded-full"
                                        src={data.companyName === 'West' ? westImg : data.companyName === 'Log' ? logImg : fsImg}
                                        alt="img"
                                    />
                                    <div className="text-black max-md:text-xs font-bold font-['Satoshi Variable'] uppercase max-lg:text-sm">{data.companyName}</div>
                                </div>
                                <div className="max-md:flex flex flex-col font-['Satoshi Variable'] gap-1 w-1/2 justify-center">
                                    <div className="flex justify-center">
                                        <span className="text-[#627BE8] font-bold text-xs">Job # :</span>
                                        <span className="font-medium text-xs ml-2">{`${data.totalJobs} job`}</span>
                                    </div>
                                </div>
                                <div className={`flex justify-end w-1/4`}>
                                    <img className="w-[30px] h-[30px]" src={`${openAcoordionResponsive === index ? upDirectionAerrow : downDirectionAerrow}`} alt="img" />
                                </div>
                            </button>
                            {/* tablat view */}
                            <div className={`${openAcoordionResponsive === index ? 'md:block hidden' : 'hidden'}`}>
                                <div className={`${openAcoordionResponsive === index ? 'md:flex hidden' : 'hidden'}  px-2.5  border-t-2 mt-3 border-[#C1C1C1] justify-between items-center pt-2.5`}>
                                    <div className="">
                                        <div className="mb-2 flex items-center">
                                            <span className="w-[50px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Name</span>
                                            <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                            {data.reward === 1 && (
                                                <div className="bg-yellow-300 rounded-full p-1 w-4 h-4 flex justify-center items-center">
                                                    <BsTrophy size={10} color="white" stroke="3" />
                                                </div>
                                            )}
                                            <div className="w-[160px] truncate">
                                                <span className=" ml-1 font-['Satoshi Variable'] font-medium text-xs">{`${data.name} ${data.surname}`}</span>
                                                <span className=" font-['Satoshi Variable'] text-[#808080] font-medium text-[10px]">{data.email ? ` (${data.email})` : ''}</span>
                                            </div>
                                        </div>
                                        <div className="mb-2 flex items-center">
                                            <span className="w-[50px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Hours #</span>
                                            <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                            <span className="font-['Satoshi Variable'] font-medium text-xs">{`${convertedHours(data.hours)} hrs.`}</span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="mb-2 flex items-center">
                                            <span className="w-[110px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Language Fluency</span>
                                            <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                            <img className="w-4 h-4 rounded-full" src={flagImg} alt="img" />
                                            <span className="ml-1.5 font-['Satoshi Variable'] font-medium text-xs">{data.languageFluency}</span>
                                        </div>
                                        <div className="mb-2 flex items-center">
                                            <div className="w-[110px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8] flex">
                                                <span>
                                                    <BsStar />
                                                </span>
                                                <span className="ml-1.5"> Ratings</span>
                                            </div>
                                            <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                            <div className="font-['Satoshi Variable'] font-medium text-xs flex">
                                                <span>
                                                    <BsStar />
                                                </span>
                                                <span className="ml-1.5"> {roundedNumber(data.rating)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="">
                                        <div className="mb-2 flex items-center">
                                            <span className="w-[90px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Job #</span>
                                            <div className="">
                                                <span className="mx-[10px] text-[#627BE8]">:</span>
                                                <span className="font-['Satoshi Variable'] font-medium text-xs">{`${data.totalJobs} job`}</span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* Mobile view */}
                            <div className={`${openAcoordionResponsive === index ? 'max-md:block max-lg:hidden' : 'hidden'}  mt-3 border-t-2 border-[#C1C1C1] p-[10px]`}>
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Name</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <div className="flex flex-row items-center gap-2">
                                        {data.reward === 1 && (
                                            <div className="bg-yellow-300 rounded-full p-1 w-4 h-4 flex justify-center items-center">
                                                <BsTrophy size={10} color="white" stroke="3" />
                                            </div>
                                        )}
                                        <div className="flex flex-col justify-center">
                                            <span className=" font-['Satoshi Variable'] font-medium text-xs">{`${data.name} ${data.surname}`}</span>
                                            <span className=" font-['Satoshi Variable'] text-[#808080] font-medium text-[10px]">{data.email}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Language Fluency</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <img className="w-4 h-4 rounded-full" src={flagImg} alt="img" />
                                    <span className="ml-1.5 font-['Satoshi Variable'] font-medium text-xs">{data.languageFluency}</span>
                                </div>
                                {/* <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Job #</span>
                                    <div className="">
                                        <span className="mx-[10px] text-[#627BE8]">:</span>
                                        <span className="font-['Satoshi Variable'] font-medium text-xs">{`${data.totalJobs} job`}</span>
                                    </div>
                                </div> */}
                                <div className="mb-2 flex items-center">
                                    <span className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8]">Hours #</span>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <span className="font-['Satoshi Variable'] font-medium text-xs">{`${convertedHours(data.hours)} hrs.`}</span>
                                </div>
                                <div className="mb-2 flex items-center">
                                    <div className="w-[120px] font-['Satoshi Variable'] font-bold text-xs text-[#627BE8] flex">
                                        <span>
                                            <BsStar />
                                        </span>
                                        <span className="ml-1.5"> Ratings</span>
                                    </div>
                                    <span className="mr-[10px] ml-[10px] text-[#627BE8]">:</span>
                                    <div className="font-['Satoshi Variable'] font-medium text-xs flex">
                                        <span>
                                            <BsStar />
                                        </span>
                                        <span className="ml-1.5">{roundedNumber(data.rating)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
};
export default ResponsiveActiveAvailabelListAccordion;
