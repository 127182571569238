/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import Scheduler, { SchedulerData, ViewTypes, CellUnits, DATE_FORMAT } from 'react-big-scheduler';
import withDragDropContext from 'src/utils/withDnDContext';
import 'react-big-scheduler/lib/css/style.css';
import 'moment/locale/en-gb';
import searchIcon from 'src/assets/svgs/searchIcon.svg';
import sortImg from 'src/assets/img/jobSortImg.png';
import { jobInEmployeeList } from 'src/services/job';
import moment from 'moment';
import flagImg from 'src/assets/img/flagImg.png';
import { BsDot, BsFillStarFill } from 'react-icons/bs';
import closeIcon from 'src/assets/img/closeImg.png';
import hashIcon from 'src/assets/svgs/hashIcon.svg';
import whiteStarIcon from 'src/assets/svgs/whiteStarIcon.svg';
import { HiOutlineUser } from 'react-icons/hi2';
import { convertedHours, dateTimeFormate, getRemovetzTime, roundedNumber } from 'src/utils/function';
import backButtonImg from 'src/assets/img/employeeBackBtnImg.png';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { breakPoints } from 'src/constants/general';
import { Paginator } from 'primereact/paginator';

// eslint-disable-next-line max-lines-per-function
const JobCreateStep2 = ({
    step1Data,
    setSelectEmployee,
    selectEmployee,
    isButton = false,
    assignEmployee = () => {},
    isBack = () => {},
    setBtnDisabled,
    isDisabled = false,
    saveOnClick = () => {},
}) => {
    const { isMobile } = useDeviceDetect();
    const [viewModel, setViewModel] = useState(null);
    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [num, setNum] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [filterApply, setFilterApply] = useState(0);
    const [pageRender, setPageRender] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [sortValue, setSortValue] = useState({ name: '', value: '' });
    const [loader, setLoader] = useState(true);
    const [called, setCalled] = useState({ mobile: false, tablet: false, desktop: false });
    const [rows, setRows] = useState(10);
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [totalEmployRecords, setTotalEmployRecords] = useState(0);
    const [reachWhenStartDate, setReachWhenStartDate] = useState('');
    const [reachWhenEndDate, setReachWhenEndDate] = useState('');
    const controller = new AbortController();
    const getScreenSizeStartDate = isMobile < breakPoints.tablet ? dateTimeFormate(step1Data?.startDate, 'YYYY-MM-DD') : step1Data.startOfPrevWeek;
    const modalData = [
        { name: 'Ratings', value: 'rating' },
        { name: 'Jobs', value: 'jobs' },
        { name: 'Hours', value: 'hours' },
        { name: 'Level', value: 'level' },
    ];
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        if (viewModel) {
            // if (windowSize.width <= 1362) {
            let updatedViewModel = viewModel;
            const breakpoints = [
                { breakpoint: 1730, adjustment: 40 },
                { breakpoint: 1650, adjustment: 60 },
                { breakpoint: 1545, adjustment: 80 },
                { breakpoint: 1440, adjustment: 100 },
                { breakpoint: 1325, adjustment: 120 },
            ];

            // Find the appropriate adjustment based on the window width
            const adjustment = breakpoints.find((breakpoint) => windowSize.width >= breakpoint.breakpoint)?.adjustment || 140;
            updatedViewModel.config.schedulerWidth =
                windowSize.width < breakPoints.tablet ? (windowSize.width < breakPoints.mobile ? windowSize.width - 80 : windowSize.width - 100) : windowSize.width - adjustment;
            setViewModel(updatedViewModel);
            forceUpdate();
            // }
        }
    }, [windowSize.width, viewModel]);

    const getCustomDate = (schedulerData, num1, date = undefined) => {
        const { viewType } = schedulerData;
        let selectDate = schedulerData.startDate;
        if (date !== undefined) selectDate = date;

        let startDate =
            num1 === 0
                ? selectDate
                : schedulerData
                      .localeMoment(selectDate)
                      .add(1 * num1, 'days')
                      .format(DATE_FORMAT);
        let endDate = schedulerData.localeMoment(startDate).add(0, 'days').format(DATE_FORMAT);
        let cellUnit = CellUnits.Day;

        if (viewType === ViewTypes.Custom) {
            startDate =
                num1 === 0
                    ? selectDate
                    : schedulerData
                          .localeMoment(selectDate)
                          .add(1 * num1, 'days')
                          .format(DATE_FORMAT);
            endDate = schedulerData.localeMoment(startDate).add(0, 'days').format(DATE_FORMAT);
            cellUnit = CellUnits.Day;
        } else if (viewType === ViewTypes.Custom1) {
            startDate =
                num1 === 0
                    ? selectDate
                    : schedulerData
                          .localeMoment(selectDate)
                          .add(3 * num1, 'days')
                          .format(DATE_FORMAT);
            endDate = schedulerData.localeMoment(startDate).add(2, 'days').format(DATE_FORMAT);
            cellUnit = CellUnits.Day;
        }

        return { startDate, endDate, cellUnit };
    };
    const schedulerData1 = new SchedulerData(
        step1Data.selectedDate[0],
        isMobile < breakPoints.mobile ? ViewTypes.Custom : isMobile < breakPoints.tablet ? ViewTypes.Custom1 : ViewTypes.Week,
        false,
        false,
        {
            customCellWidth: isMobile < breakPoints.mobile ? 120 : isMobile < breakPoints.tablet ? 340 : '',
            startResizable: false,
            endResizable: false,
            movable: false,
            creatable: false,
            resourceName: 'dhdhhd',
            displayWeekend: true,
            eventItemPopoverEnabled: false,
            nonWorkingTimeHeadBgColor: 'white',
            nonWorkingTimeBodyBgColor: 'white',
            schedulerWidth: '100%',
            views: [
                {
                    viewName: 'Two days',
                    viewType: isMobile < breakPoints.mobile ? ViewTypes.Custom : isMobile < breakPoints.tablet ? ViewTypes.Custom1 : ViewTypes.Week,
                    showAgenda: false,
                    isEventPerspective: false,
                },
            ],
        },
        {
            getCustomDateFunc: (schedulerData, num1, date) => (pageRender ? (isMobile < breakPoints.tablet ? getCustomDate(schedulerData, num1, date) : '') : ''),
        },
    );

    useEffect(() => {
        if (!isButton) {
            setSelectEmployee([]);
        }
    }, []);

    useEffect(() => {
        if (!isButton) {
            if (loader) {
                setBtnDisabled(true);
            } else {
                setBtnDisabled(false);
            }
        }
    }, [loader]);

    const getViewModel = (data) => {
        setViewModel(data);
    };
    const addOneDay = (dateString) => {
        const originalDate = moment(dateString);
        const addOneDate = originalDate.add(1, 'days');
        const resultString = addOneDate.format('YYYY-MM-DD');
        return resultString;
    };
    const checkBackgroundColor = (status) => {
        let color = '';
        if (status === 'Available') {
            color = '#F58585';
        } else if (status === 'Unavailable') {
            color = '#AF8AEE';
        } else if (status === 'On Hold') {
            color = '#627BE8';
        } else if (status === 'Busy') {
            color = '#FFCC15';
        } else if (status === 'Fired') {
            color = '#CC5500';
        } else if (status === 'Quit') {
            color = 'pink';
        } else {
            color = '#86D48F';
        }
        return color;
    };
    const getEmployList = async (url, type = '', signal = '') => {
        setLoader(true);
        const employee = await jobInEmployeeList(url, signal);
        let resources1 = employee?.data?.data?.map((data) => ({
            id: String(data.employeeId),
            name: `${data.name}`,
            surname: data.surname,
            totalJobs: data.totalJobs,
            hours: data.hours,
            rating: data.rating,
            languageFluency: data.languageFluency,
        }));
        if (employee !== undefined) {
            setLoader(false);
            setTotalEmployRecords(employee?.data?.total);
        }
        setResources(resources1 ? resources1 : []);
        if (type === 'pageRender') {
        }
        let transformedArray = employee?.data?.data
            ?.map((emp) =>
                emp.groupedDailyStatus?.map((status, index) => ({
                    id: `${emp.employeeId}-${index + 1}`, // Unique ID combining employeeId and index
                    start: getRemovetzTime(status.startDate, 'YYYY-MM-DD'),
                    end: addOneDay(getRemovetzTime(status.endDate, 'YYYY-MM-DD')),
                    resourceId: `${emp.employeeId}`,
                    title: status.status,
                    bgColor: checkBackgroundColor(status.status),
                })),
            )
            .flat();
        setEvents(transformedArray ? transformedArray : []);
        setPageRender(false);
        // }
    };
    const responsiveChangeSheduler = () => {
        const newViewType = isMobile < breakPoints.mobile ? ViewTypes.Custom : isMobile < breakPoints.tablet ? ViewTypes.Custom1 : ViewTypes.Week;
        const threeDate = moment(reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate);
        const threeDaysLater = threeDate.add(2, 'days');
        setEvents([]);
        getEmployList(
            `?startDate=${reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate}&endDate=${
                isMobile < breakPoints.mobile
                    ? reachWhenStartDate
                        ? reachWhenStartDate
                        : getScreenSizeStartDate
                    : isMobile < breakPoints.tablet
                    ? threeDaysLater.format('YYYY-MM-DD')
                    : reachWhenEndDate
                    ? reachWhenEndDate
                    : step1Data.endOfNextWeek
            }
            &companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&page=${page + 1}&limit=${rows}`,
            'pageRender',
            controller.signal,
        );
        if (resources?.length !== 0) {
            if (viewModel) {
                let schedulerData2 = viewModel;
                schedulerData2.setResources(resources);
                schedulerData2.localeMoment('en-gb');
                schedulerData2.setEvents(events);
                schedulerData2.setViewType(newViewType, false, false);
                getViewModel(schedulerData2);
            } else {
                schedulerData1.setResources(resources);
                schedulerData1.localeMoment('en-gb');
                schedulerData1.setEvents(events);
                schedulerData1.setViewType(newViewType, false, false);
                getViewModel(schedulerData1);
            }
        } else {
            if (viewModel) {
                let schedulerData3 = viewModel;
                schedulerData3?.setResources(resources);
                schedulerData3?.localeMoment('en-gb');
                schedulerData3?.setEvents(events);
                schedulerData3.setViewType(newViewType, false, false);
                getViewModel(schedulerData3);
            } else {
                schedulerData1.setResources(resources);
                schedulerData1.localeMoment('en-gb');
                schedulerData1.setEvents(events);
                schedulerData1.setViewType(newViewType, false, false);
                getViewModel(schedulerData1);
            }
        }
        forceUpdate();
    };
    useEffect(() => {
        if (!pageRender) {
            let updatedCalled = { mobile: false, tablet: false, desktop: false };

            if (isMobile >= 0 && isMobile <= 767 && !called.mobile) {
                updatedCalled.mobile = true;
            } else if (isMobile >= 768 && isMobile <= 1279 && !called.tablet) {
                updatedCalled.tablet = true;
            } else if (isMobile >= 1280 && !called.desktop) {
                updatedCalled.desktop = true;
            }

            if (updatedCalled.mobile || updatedCalled.tablet || updatedCalled.desktop) {
                responsiveChangeSheduler();
                setCalled(updatedCalled);
            }
        }
    }, [isMobile]);
    const searchOnchange = (event) => {
        setSearchInput(event.target.value);
    };

    useEffect(() => {
        const threeDate = moment(reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate);
        const threeDaysLater = threeDate.add(2, 'days');
        if (searchInput && filterApply) {
            getEmployList(
                `?startDate=${reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate}&endDate=${
                    isMobile < breakPoints.mobile
                        ? reachWhenStartDate
                            ? reachWhenStartDate
                            : getScreenSizeStartDate
                        : isMobile < breakPoints.tablet
                        ? threeDaysLater.format('YYYY-MM-DD')
                        : reachWhenEndDate
                        ? reachWhenEndDate
                        : step1Data.endOfNextWeek
                }&companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&search=${searchInput}&sortBy=${sortValue.value}&page=${page + 1}&limit=${rows}`,
                '',
                controller.signal,
            );
        } else if (searchInput) {
            getEmployList(
                `?startDate=${reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate}&endDate=${
                    isMobile < breakPoints.mobile
                        ? reachWhenStartDate
                            ? reachWhenStartDate
                            : getScreenSizeStartDate
                        : isMobile < breakPoints.tablet
                        ? threeDaysLater.format('YYYY-MM-DD')
                        : reachWhenEndDate
                        ? reachWhenEndDate
                        : step1Data.endOfNextWeek
                }&companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&search=${searchInput}&page=${page + 1}&limit=${rows}`,
                '',
                controller.signal,
            );
        } else if (filterApply) {
            getEmployList(
                `?startDate=${reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate}&endDate=${
                    isMobile < breakPoints.mobile
                        ? reachWhenStartDate
                            ? reachWhenStartDate
                            : getScreenSizeStartDate
                        : isMobile < breakPoints.tablet
                        ? threeDaysLater.format('YYYY-MM-DD')
                        : reachWhenEndDate
                        ? reachWhenEndDate
                        : step1Data.endOfNextWeek
                }&companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&sortBy=${sortValue.value}&page=${page + 1}&limit=${rows}`,
                '',
                controller.signal,
            );
        } else {
            getEmployList(
                `?startDate=${reachWhenStartDate ? reachWhenStartDate : getScreenSizeStartDate}&endDate=${
                    isMobile < breakPoints.mobile
                        ? reachWhenStartDate
                            ? reachWhenStartDate
                            : getScreenSizeStartDate
                        : isMobile < breakPoints.tablet
                        ? threeDaysLater.format('YYYY-MM-DD')
                        : reachWhenEndDate
                        ? reachWhenEndDate
                        : step1Data.endOfNextWeek
                }&companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&page=${page + 1}&limit=${rows}`,
                pageRender ? 'pageRender' : '',
                controller.signal,
            );
        }
        // setPageRender(false);
        return () => controller.abort();
    }, [searchInput, filterApply, page, rows]);

    useEffect(() => {
        if (resources?.length !== 0) {
            if (viewModel) {
                let schedulerData2 = viewModel;
                schedulerData2.setResources(resources);
                schedulerData2.localeMoment('en-gb');
                schedulerData2.setEvents(events);
                getViewModel(schedulerData2);
                forceUpdate();
            } else {
                schedulerData1.setResources(resources);
                schedulerData1.localeMoment('en-gb');
                schedulerData1.setEvents(events);
                getViewModel(schedulerData1);
            }
        } else {
            if (viewModel) {
                let schedulerData3 = viewModel;
                schedulerData3?.setResources(resources);
                schedulerData3?.localeMoment('en-gb');
                schedulerData3?.setEvents(events);
                getViewModel(schedulerData3);
                forceUpdate();
            } else {
                schedulerData1.setResources(resources);
                schedulerData1.localeMoment('en-gb');
                schedulerData1.setEvents(events);
                getViewModel(schedulerData1);
            }
        }
    }, [resources]);
    useEffect(() => {
        if (isMobile < breakPoints.tablet) {
            const shedulerWidthSize = isMobile < breakPoints.mobile ? ((525 - 120) / (767 - 370)) * (isMobile - 370) + 120 : 170 + (170 * (isMobile - 768)) / (1280 - 768);
            if (resources?.length !== 0) {
                if (viewModel) {
                    let schedulerData2 = viewModel;
                    schedulerData2.setResources(resources);
                    schedulerData2.localeMoment('en-gb');
                    schedulerData2.setEvents(events);
                    schedulerData2.config.customCellWidth = shedulerWidthSize;
                    getViewModel(schedulerData2);
                } else {
                    schedulerData1.setResources(resources);
                    schedulerData1.localeMoment('en-gb');
                    schedulerData1.setEvents(events);
                    schedulerData1.config.customCellWidth = shedulerWidthSize;
                    getViewModel(schedulerData1);
                }
            } else {
                if (viewModel) {
                    let schedulerData3 = viewModel;
                    schedulerData3?.setResources(resources);
                    schedulerData3?.localeMoment('en-gb');
                    schedulerData3?.setEvents(events);
                    schedulerData3.config.customCellWidth = shedulerWidthSize;
                    getViewModel(schedulerData3);
                } else {
                    schedulerData1.setResources(resources);
                    schedulerData1.localeMoment('en-gb');
                    schedulerData1.setEvents(events);
                    schedulerData1.config.customCellWidth = shedulerWidthSize;
                    getViewModel(schedulerData1);
                }
            }
            forceUpdate();
        }
    }, [isMobile]);
    const checKDateBetWeen = async (tgtDate, weekStarDate, weekEndDate, clickType) => {
        setReachWhenStartDate(weekStarDate);
        setReachWhenEndDate(weekEndDate);
        // const startDate = moment(events[0]?.start);
        // const endDate = moment(events[events.length - 1]?.end);
        moment(tgtDate);

        // const isDateEqualOrBetween = targetDate.isBetween(startDate, endDate) || targetDate.isSame(startDate);
        // const isDateEqualOrBetweenArray = events?.filter((item) => {
        //     const startMoment = moment(item.start);
        //     const endMoment = moment(item.end);
        //     return targetDate.isBetween(startMoment, endMoment) || targetDate.isSame(startMoment);
        // });
        // if (isDateEqualOrBetweenArray?.length === 0) {
        setLoader(true);
        setNum(num + 1);
        const employee1 = await jobInEmployeeList(
            searchInput
                ? `?startDate=${weekStarDate}&endDate=${weekEndDate}&companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&page=${page + 1}&limit=${rows}&search=${searchInput}`
                : `?startDate=${weekStarDate}&endDate=${weekEndDate}&companyId=${step1Data.companyType.id}&jobTypeId=${step1Data.jobType.id}&page=${page + 1}&limit=${rows}`,
        );
        let transformedArray1 = employee1?.data?.data
            ?.map((emp) =>
                emp.groupedDailyStatus?.map((status, index) => ({
                    id: `${num}-${emp.employeeId}-${index + 1}`, // Unique ID combining employeeId and index
                    start: getRemovetzTime(status.startDate, 'YYYY-MM-DD'),
                    end: addOneDay(getRemovetzTime(status.endDate, 'YYYY-MM-DD')),
                    resourceId: `${emp.employeeId}`,
                    title: status.status,
                    bgColor: checkBackgroundColor(status.status),
                })),
            )
            .flat();
        setLoader(false);
        if (clickType === 'previous') {
            const preInitEvents = [...transformedArray1];
            setEvents(transformedArray1);
            return preInitEvents;
        }
        if (clickType === 'next') {
            const nextInitEvents = [...transformedArray1];
            setEvents(transformedArray1);
            return nextInitEvents;
        }
        // }
        // return false;
    };
    const sortOnclick = (data) => {
        setSortValue({ name: data.name, value: data.value });
        setModalOpen(false);
        setFilterApply(filterApply + 1);
    };

    const handlePrevClick = async (schedulerData) => {
        schedulerData.prev();
        const prevEvents = await checKDateBetWeen(schedulerData.startDate, schedulerData.startDate, schedulerData.endDate, 'previous');
        schedulerData.setEvents(prevEvents ? prevEvents : events);
        setViewModel(schedulerData);
        forceUpdate();
    };

    const handleNextClick = async (schedulerData) => {
        schedulerData.next();
        const nextEvents = await checKDateBetWeen(schedulerData.endDate, schedulerData.startDate, schedulerData.endDate, 'next');
        schedulerData.setEvents(nextEvents ? nextEvents : events);
        setViewModel(schedulerData);
        forceUpdate();
    };

    const handleViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        setViewModel(schedulerData);
        forceUpdate();
    };

    const handleSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        setViewModel(schedulerData);
        forceUpdate();
    };

    const checkBoxChange = (e) => {
        const id = Number(e.target.value);

        if (selectEmployee.includes(id)) {
            setSelectEmployee(selectEmployee.filter((checkedId) => checkedId !== id));
        } else {
            setSelectEmployee([...selectEmployee, id]);
        }
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.page);
    };

    const nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
        let datetime = schedulerData.localeMoment(item.time);
        let isCurrentDate = false;

        const dateSelected = step1Data.selectedDate;
        isCurrentDate = dateSelected.some((data) => datetime.isSame(data, 'day'));

        if (isCurrentDate) {
            style.backgroundColor = 'black';
            style.color = 'white';
            style.className = 'active';
        }

        return (
            <th
                key={item.time}
                className={
                    isCurrentDate
                        ? datetime.isSame(dateSelected[0], 'day')
                            ? `header3-text header3-text-active ${dateSelected?.length === 1 ? 'header_single' : 'header_first'}`
                            : datetime.isSame(dateSelected[dateSelected?.length - 1], 'day')
                            ? `header3-text header3-text-active header_last`
                            : `header3-text header3-text-active`
                        : 'header3-text'
                }
                style={style}
            >
                {formattedDateItems?.map((formattedItem, index) => (
                    <div
                        key={index}
                        dangerouslySetInnerHTML={{
                            __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>'),
                        }}
                    />
                ))}
            </th>
        );
    };

    if (!viewModel) {
        return '';
    }
    return (
        <div className="max-lg:px-0">
            <div className="schedular_dt bg-white px-6 py-4 rounded-2xl max-lg:bg-lightgray max-lg:p-0 max-lg:rounded-none max-lg:px-0">
                <div className="hidden max-lg:block rounded-2xl bg-white p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <button className="max-md:h-6 max-md:w-6 max-lg:h-[30px] max-lg:w-[30px]" onClick={() => isBack()}>
                                <img src={backButtonImg} alt="backaerrowimg" />
                            </button>
                            <p className="font-black max-md:text-sm max-lg:text-base">Choose Employees</p>
                        </div>
                        <div className="relative max-md:w-[40%] max-lg:w-[30%]">
                            <input
                                className="w-full focus-visible:outline-none max-md:h-8 max-lg:h-9 px-4 py-[10px] pr-8 bg-white rounded-3xl border border-gray10"
                                placeholder="Search"
                                disabled={pageRender ? true : false}
                                onChange={searchOnchange}
                            />

                            <img className="w-5 h-5 absolute right-2 max-md:top-[6px] max-lg:top-[8px]" src={searchIcon} alt="searchicon" />
                        </div>
                    </div>
                    <div className="flex max-md:flex-col max-lg:flex-row-reverse max-md:items-start max-md:justify-start max-lg:items-center max-lg:justify-between">
                        <div className="flex  max-lg:gap-2 lg:items-center mt-5 justify-between">
                            <div className="flex flex-row items-center">
                                <div className="text-zinc-800 max-md:text-[10px] max-lg:text-[11px]  font-normal font-['Satoshi Variable']">Job Details:</div>
                                <div className="flex items-center">
                                    <BsDot size={18} />
                                    <div className="text-zinc-800 max-md:text-[10px] max-lg:text-[11px]  font-normal font-['Satoshi Variable']">{step1Data.jobType.name}</div>
                                </div>
                                <div className="flex items-center">
                                    <BsDot size={18} />
                                    <div className="text-zinc-800 max-md:text-[10px] max-lg:text-[11px]  font-normal font-['Satoshi Variable']">{step1Data.workers} Workers</div>
                                </div>
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="flex">
                                    <HiOutlineUser size={14} className="ms-1 max-lg:w-3" />
                                    <HiOutlineUser size={14} className="ms-1 text-gray40 max-lg:w-3" />
                                    <HiOutlineUser size={14} className="ms-1 text-gray40 max-lg:w-3" />
                                    <HiOutlineUser size={14} className="ms-1 text-gray40 max-lg:w-3" />
                                </div>
                                <div className="flex items-center">
                                    <BsDot size={18} />
                                    <div className="text-zinc-800 max-md:text-[10px] max-lg:text-[12px]  font-normal font-['Satoshi Variable']">
                                        {step1Data.endDate
                                            ? `${moment(step1Data.startDate).format('DD')}-${moment(step1Data.endDate).format('DD MMMM')}`
                                            : `${moment(step1Data.startDate).format('DD MMMM')}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="max-md:text-xs font-bold font-['Satoshi Variable'] mb-2 max-lg:text-sm">Sort by</div>
                            <div className="flex items-center max-md:gap-2 max-lg:gap-4">
                                {modalData?.map((data, index) => (
                                    <button
                                        className={`px-3 py-2 rounded-3xl border border-[#DFE1E2] justify-center items-center gap-1 flex
                                 ${sortValue.value === data.value ? 'bg-black text-white' : 'bg-white text-black '}`}
                                        onClick={() => {
                                            if (sortValue.value === data.value) {
                                                setSortValue({
                                                    name: '',
                                                    value: '',
                                                });
                                                setFilterApply(0);
                                            } else {
                                                sortOnclick(data);
                                            }
                                        }}
                                        key={index}
                                    >
                                        <img className="w-4 h-4 rounded-full" src={data.name === 'Ratings' ? whiteStarIcon : data.name === 'Level' ? flagImg : hashIcon} alt="img" />
                                        <div className="text-xs font-normal font-['Satoshi Variable'] whitespace-nowrap">{data.name}</div>
                                        {sortValue.value === data.value ? <img src={closeIcon} alt="closeicon" className="h-4 w-4 cursor-pointer" /> : ''}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center mb-4 max-lg:hidden">
                    {!isButton && (
                        <div className="flex items-center gap-2">
                            <button className="h-6 w-6" onClick={() => isBack()}>
                                <img src={backButtonImg} alt="backaerrowimg" />
                            </button>
                            <p className="font-bold">Back</p>
                        </div>
                    )}
                    <div className="flex gap-2">
                        <div className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">Job Details:</div>
                        <BsDot size={18} />
                        <div className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">{step1Data.jobType.name}</div>
                        <BsDot size={18} />
                        <div className="flex">
                            <div className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">{step1Data.workers} Workers</div>
                            <HiOutlineUser size={16} className="ms-1" />
                            <HiOutlineUser size={16} className="ms-1 text-gray40" />
                            <HiOutlineUser size={16} className="ms-1 text-gray40" />
                            <HiOutlineUser size={16} className="ms-1 text-gray40" />
                        </div>
                        <BsDot size={18} />
                        <div className="text-zinc-800 text-xs font-normal font-['Satoshi Variable'] me-3">
                            {step1Data.endDate ? `${moment(step1Data.startDate).format('DD')}-${moment(step1Data.endDate).format('DD MMMM')}` : `${moment(step1Data.startDate).format('DD MMMM')}`}
                        </div>
                        {/* <BsDot size={18} /> */}
                    </div>
                </div>
                <div className="flex items-center justify-between max-lg:hidden">
                    <h3 className="text-black text-base font-black font-['Satoshi Variable']">Choose Employees</h3>
                    <div className="relative">
                        <input
                            className="w-40 focus-visible:w-52 focus-visible:outline-none h-10 px-5 py-2 pr-10 bg-white rounded-3xl border border-gray10"
                            placeholder="Search"
                            disabled={pageRender ? true : false}
                            onChange={searchOnchange}
                        />

                        <img className="w-6 h-6 absolute right-3 top-2" src={searchIcon} alt="searchicon" />
                    </div>
                </div>
                <div className="flex max-lg:bg-white max-lg:p-3 max-lg:rounded-2xl lg:items-baseline max-h-[calc(100vh-410px)] relative overflow-y-auto overflow-x-hidden w-full job-calender mt-3">
                    <div className="w-[190px] relative pt-10 lg:pt-0">
                        <div className="flex items-center h-14 max-lg:hidden ">
                            <>
                                <button
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                    disabled={pageRender ? true : false}
                                >
                                    <img src={sortImg} alt="sortimage" className="h-6 w-6 me-2" />
                                </button>
                                {!sortValue.name ? (
                                    <h2 className="text-black text-xs font-bold font-['Satoshi Variable']">Sort by</h2>
                                ) : (
                                    <div className={`w-28 px-4 py-2 rounded-3xl border border-gray20 justify-center items-center gap-1 flex bg-black text-white`}>
                                        <img className="w-4 h-4 rounded-full" src={sortValue.name === 'Ratings' ? whiteStarIcon : sortValue.name === 'Level' ? flagImg : hashIcon} alt="img" />

                                        <div className="text-xs font-normal font-['Satoshi Variable'] whitespace-nowrap">{sortValue.name}</div>
                                        <img
                                            src={closeIcon}
                                            alt="closeicon"
                                            className="h-6 w-6 cursor-pointer"
                                            onClick={() => {
                                                setSortValue({
                                                    name: '',
                                                    value: '',
                                                });
                                                setFilterApply(0);
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                            {modalOpen && (
                                <div className="max-w-sm absolute top-0  bg-black shadow-lg rounded-lg overflow-hidden py-2 px-4 text-white">
                                    <div className="flex items-center gap-2">
                                        <img src={sortImg} alt="sortimage" className="h-6 w-6 me-2" />
                                        <div className="text-xs font-bold font-['Satoshi Variable']">Sort by</div>
                                        <div className=" flex justify-center">
                                            <img
                                                src={closeIcon}
                                                alt="closeicon"
                                                className="h-5 w-5 cursor-pointer rounded-full"
                                                onClick={() => {
                                                    setModalOpen(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {modalData?.map((data, index) => (
                                            <button
                                                className={`w-28 my-2 px-4 py-2 rounded-3xl border border-gray100 justify-center items-center gap-1 flex bg-black text-white`}
                                                onClick={() => sortOnclick(data)}
                                                key={index}
                                            >
                                                <img className="w-4 h-4 rounded-full" src={data.name === 'Ratings' ? whiteStarIcon : data.name === 'Level' ? flagImg : hashIcon} alt="img" />
                                                <div className="text-xs font-normal font-['Satoshi Variable'] whitespace-nowrap">{data.name}</div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        {resources?.map((data, index) => (
                            <div className={`border mb-[10px] px-2 pt-1 pb-2 me-3 rounded-md `} key={index}>
                                <h5 className="text-black text-xs font-medium font-['Satoshi Variable'] ml-5 mb-2 truncate">{`${data.name} ${data.surname}`}</h5>
                                <div className="flex gap-2 mb-2">
                                    <input
                                        type="checkbox"
                                        id="empCheck"
                                        name="empCheck"
                                        className="accent-black cursor-pointer"
                                        value={data.id}
                                        onChange={checkBoxChange}
                                        checked={selectEmployee.includes(Number(data.id))}
                                    />
                                    <div className="flex items-center gap-1">
                                        <div className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">Jobs:</div>
                                        <div className="text-black text-xs font-normal font-['Satoshi Variable']">{data.totalJobs}</div>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <div className="text-gray-500 text-xs font-normal font-['Satoshi Variable']">Hrs:</div>
                                        <div className="text-black text-xs font-normal font-['Satoshi Variable']">{convertedHours(data.hours)}</div>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <BsFillStarFill className="h-3 w-3 text-gray80 mb-[2px]" />
                                        <div className="text-gray80 text-xs font-normal font-['Satoshi Variable'] text-clip overflow-hidden w-5">{roundedNumber(data.rating)}</div>
                                    </div>
                                </div>

                                <div className="flex items-center gap-1 ml-5">
                                    <img className="w-4 h-4 rounded-full" src={flagImg} alt="img" />
                                    <div className="text-zinc-800 text-xs font-normal font-['Satoshi Variable']">{data.languageFluency}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="schedular_dt schedule-custom w-full">
                        <Scheduler
                            schedulerData={viewModel}
                            prevClick={(schedulerData) => handlePrevClick(schedulerData)}
                            nextClick={(schedulerData) => handleNextClick(schedulerData)}
                            onViewChange={(schedulerData, view) => handleViewChange(schedulerData, view)}
                            onSelectDate={(schedulerData, date) => handleSelectDate(schedulerData, date)}
                            nonAgendaCellHeaderTemplateResolver={(schedulerData, item, formattedDateItems, style) =>
                                nonAgendaCellHeaderTemplateResolver(schedulerData, item, formattedDateItems, style)
                            }
                        />
                        {resources?.length === 0 && <p className="mt-3 text-lg text-center">{loader ? 'Loading Data...' : 'No Employee Record Found'}</p>}
                    </div>
                </div>
                <div className="mt-4">
                    <Paginator
                        first={first}
                        rows={rows}
                        onPageChange={onPageChange}
                        totalRecords={totalEmployRecords}
                        className={`datatable_pagination ${isMobile < breakPoints.tablet ? '' : 'datatable_pagination_sheduler'}`}
                        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    />
                </div>
                {isButton && (
                    <div className="flex justify-end mt-3 max-lg:px-2">
                        <button
                            className={`max-lg:w-full w-46 h-12 px-6 py-4  ${loader || selectEmployee?.length === 0 ? 'bg-[#D0D7F8]' : 'bg-secondary'}
                     rounded-3xl shadow justify-center items-center gap-2 inline-flex text-white text-sm font-bold font-['Satoshi Variable'] uppercase`}
                            onClick={() => {
                                assignEmployee();
                            }}
                            disabled={loader ? true : selectEmployee?.length === 0 ? true : false}
                            type={'button'}
                        >
                            save
                        </button>
                    </div>
                )}
                {!isButton && (
                    <div className="hidden justify-end mt-3 max-lg:px-2 max-lg:flex">
                        <button
                            className={`max-lg:w-full w-46 h-12 px-6 py-4  ${isDisabled ? 'bg-[#D0D7F8]' : 'bg-secondary'}
                     rounded-3xl shadow justify-center items-center gap-2 inline-flex text-white text-sm font-bold font-['Satoshi Variable'] uppercase`}
                            onClick={() => {
                                saveOnClick();
                            }}
                            disabled={isDisabled}
                            type={'button'}
                        >
                            save
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withDragDropContext(JobCreateStep2);
